import React, { useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import _ from "lodash";
import { ChangeEvent } from "preact/compat";
import { displayName } from "react-quill";

type Props = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string | undefined) => void;
  values: {
    description: string;
    material: string;
    reconsiderations: string[];
  };
  errorForm: {
    description: string;
    material: string;
    reconsiderations: string;
  };
  reconsiderationOptions: string[];
};

const DescriptionRequest = ({
  values,
  errorForm,
  setFieldError,
  setFieldValue,
  reconsiderationOptions,
}: Props) => {
  const handleTextChange = (event) => {
    setFieldValue(event.target.name, event.target.value, false);
    setFieldError(event.target.name, "");
  };

  console.log(errorForm);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    const value = event.target.value;

    if (isChecked) {
      setFieldValue(
        "reconsiderations",
        [...values.reconsiderations, value],
        false
      );
    } else {
      const array = values.reconsiderations;
      const index = array.indexOf(value);
      array.splice(index, 1);
      setFieldValue("reconsiderations", array, false);
    }
    setFieldError("reconsiderations", "");
  };

  useEffect(() => {
    setFieldError("description", "");
    setFieldError("material", "");
    setFieldError("reconsiderations", "");
  }, []);

  return (
    <div className="form-card text-start">
      <Row>
        <div className="col-7">
          <h3 className="mb-4">Description of Request:</h3>
        </div>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="form-group">
            <Form.Label>Detailed Description</Form.Label>
            <Form.Control
              as="textarea"
              value={values.description}
              name="description"
              isInvalid={!_.isEmpty(errorForm.description)}
              onChange={handleTextChange}
            />
            {errorForm.description && (
              <Form.Control.Feedback type="invalid">
                {errorForm.description as string}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-group">
            <Form.Label>Materials Needed</Form.Label>
            <Form.Control
              as="textarea"
              value={values.material}
              name="material"
              isInvalid={!_.isEmpty(errorForm.material)}
              onChange={handleTextChange}
            />
            {errorForm.material && (
              <Form.Control.Feedback type="invalid">
                {errorForm.material as string}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group className="form-group">
            {reconsiderationOptions.map((rec, key) => {
              const checked = values.reconsiderations.includes(rec);

              return (
                <Form.Check
                  key={key}
                  type="checkbox"
                  label={rec}
                  checked={checked}
                  onChange={handleCheckboxChange}
                  value={rec}
                  isInvalid={!_.isEmpty(errorForm.reconsiderations)}
                />
              );
            })}
            {errorForm.reconsiderations && (
              <Form.Control.Feedback
                type="invalid"
                style={{
                  display: !_.isEmpty(errorForm.reconsiderations)
                    ? "block"
                    : "none",
                }}
              >
                {errorForm.reconsiderations as string}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default DescriptionRequest;
