import React, { useState } from "react";
import { Card, Form, Col, Button, Stack } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { ViolationTimelineDetailsModel } from "../../../model/violations/violationsmodel";
import UploadFile from "../../../components/UploadFile";
import useFileView from "../../../hooks/useFileView";
import { useUploadViolationFileMutation } from "../../../store/query/violations/violationsquery";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utilities/notificationToast";
import FileViewModal from "../../../components/FileViewModal";
import CameraModal from "../../../components/CameraModal";

type Props = {
  details: ViolationTimelineDetailsModel;
};

const ViolationDetailForm = ({ details }: Props) => {
  const [openCam, setOpenCam] = useState<boolean>(false);
  const [viewDoc, handleToggleDoc] = useFileView();
  const formSchema = Yup.object().shape({
    documents: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string(),
          file: Yup.string(),
        })
      )
      .min(1, "Please provide a document."),
  });
  const [uploadMutate, { isLoading, reset, error }] =
    useUploadViolationFileMutation();

  const {
    handleSubmit,
    setFieldValue,
    setFieldError,
    values,
    errors,
    resetForm,
  } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema: formSchema,
    initialValues: {
      documents: [],
    },
    onSubmit: (values) => {
      handleFormSubmit(values.documents);
    },
  });

  const handleToggleCam = () => setOpenCam((prev) => !prev);

  const handleFormSubmit = async (values: { name: string; file: string }[]) => {
    Promise.all(
      values.map((item) => uploadMutate({ id: details.id, body: item }))
    )
      .then(() => {
        showToastSuccess({
          text: "Documents successfully updated.",
          toastId: "success",
        });

        resetForm();
      })
      .catch((err) => {
        console.log(err);
        errorDisplayOrNavigate({
          text: "Something went wrong.",
          toastId: "log-error",
        });
      });
  };

  if (error) {
    errorDisplayOrNavigate({ error, toastId: "upload-error" });
    reset();
  }

  return (
    <>
      {openCam && (
        <CameraModal<{ file: string; name: string }[]>
          open={openCam}
          handleToggle={handleToggleCam}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
          name="documents"
          docData={values.documents}
        />
      )}
      {viewDoc.open && (
        <FileViewModal doc={viewDoc} handleToggleView={handleToggleDoc} />
      )}
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">Details</h4>
          </div>
        </Card.Header>
        <Card.Body className="pt-0">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="form-group">
              <Form.Label>Status</Form.Label>
              <Form.Select disabled>
                <option>{details?.status || ""}</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Assigned</Form.Label>
              <Form.Control
                type="text"
                value={details?.assigned || ""}
                disabled
              ></Form.Control>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Date Filed</Form.Label>
              <Form.Control
                type="date"
                value={details?.createdAt || ""}
                disabled
              ></Form.Control>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Watchers</Form.Label>
              {details?.watchers.map((watcher, index) => (
                <Form.Check
                  key={index}
                  label={watcher}
                  type="checkbox"
                  checked
                  disabled
                ></Form.Check>
              ))}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Attach a File or Take a Snap</Form.Label>
              <Stack gap={2} direction="horizontal">
                <UploadFile<{ name: string; file: string }[]>
                  errorForm={errors.documents as string}
                  loading={isLoading}
                  setFieldError={setFieldError}
                  setFieldValue={setFieldValue}
                  isInvalid={!_.isEmpty(errors.documents)}
                  docData={values.documents}
                  name="documents"
                />
                <div
                  style={{
                    width: 1,
                    height: 40,
                    backgroundColor: "#ccc",
                  }}
                />
                <Button
                  type="button"
                  variant="primary"
                  className="d-inline-flex mb-1 me-1"
                  disabled={isLoading}
                  onClick={handleToggleCam}
                >
                  <i className="material-symbols-outlined me-0">photo_camera</i>
                </Button>
              </Stack>
            </Form.Group>
            <Form.Group className="form-group">
              <div className="mt-3">
                {[...details.documents, ...values.documents].map((doc, i) => (
                  <div key={i}>
                    <Card
                      onClick={() =>
                        handleToggleDoc(true, `${doc.file}`, doc.name)
                      }
                      style={{
                        background: "#f1f1f1",
                        cursor: "pointer",
                      }}
                    >
                      <Card.Body
                        style={{
                          padding: "10px 15px",
                          color: "#7e8b9a",
                        }}
                      >
                        {doc.name}
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
            </Form.Group>
            <Col className="mb-2">
              <Button type="submit" disabled={isLoading}>
                Update
              </Button>
            </Col>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default ViolationDetailForm;
