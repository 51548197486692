import React from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useGetRequestCountQuery } from "../../store/query/dashboard/dashboardquery";
import { errorDisplayOrNavigate } from "../../utilities/notificationToast";

const arcReq = require("../../assets/images/user/arc-request.jpg");
const violation = require("../../assets/images/user/violation.jpg");
const billStatus = require("../../assets/images/user/statement.jpg");
const loader = require("../../assets/images/page-img/page-load-loader.gif");

type Props = {};

function RequestComp({}: Props) {
  const { data, error, isLoading } = useGetRequestCountQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const loading = isLoading;

  if (error) {
    errorDisplayOrNavigate({ error, toastId: "request-error" });
  }

  return (
    <Card>
      <div className="card-header d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title text-capitalize">Your Requests</h4>
        </div>
      </div>
      <Card.Body className="pt-0">
        {loading ? (
          <div className="col-sm-12 text-center">
            <img src={loader} alt="loader" style={{ height: "100px" }} />
          </div>
        ) : (
          <ul className="list-inline m-0 p-0">
            <li className="d-flex align-items-center gap-3 mb-3">
              <img
                src={arcReq}
                alt="story-img"
                className="avatar-60 avatar-borderd object-cover avatar-rounded img-fluid d-inline-block"
              />{" "}
              <Link to="/dashboard/app/arc-requests">
                <div>
                  <h5 className="d-inline-block">ARC</h5>

                  <small className="text-capitalize d-block">
                    {data && data.arcRequests > 0
                      ? `${data.arcRequests} Request${
                          data.arcRequests > 1 ? "s" : ""
                        }`
                      : "0 Request"}
                  </small>
                </div>
              </Link>
              {/* <div className="d-flex align-items-center flex-shrink-0 gap-2">
        <button className="btn btn-primary-subtle p-1 lh-1">
          <i className="material-symbols-outlined font-size-14">
            add
          </i>
        </button>
        <button className="btn btn-danger-subtle p-1 lh-1">
          <i className="material-symbols-outlined font-size-14">
            close
          </i>
        </button>
      </div> */}
            </li>
            <li className="d-flex align-items-center gap-3 mb-3">
              <img
                src={violation}
                alt="story-img"
                className="avatar-60 avatar-borderd object-cover avatar-rounded img-fluid d-inline-block"
              />
              <Link to="/dashboard/app/violation-requests">
                <div>
                  <h5 className="d-inline-block">Violation</h5>

                  <small className="text-capitalize d-block">
                    {data && data.violationReports > 0
                      ? `Status +${data.violationReports} more`
                      : ""}
                  </small>
                </div>
              </Link>
              {/* <div className="d-flex align-items-center flex-shrink-0 gap-2">
        <button className="btn btn-primary-subtle p-1 lh-1">
          <i className="material-symbols-outlined font-size-14">
            add
          </i>
        </button>
        <button className="btn btn-danger-subtle p-1 lh-1">
          <i className="material-symbols-outlined font-size-14">
            close
          </i>
        </button>
      </div> */}
            </li>
            <li className="d-flex align-items-center gap-3 mb-3">
              <img
                src={billStatus}
                alt="story-img"
                className="avatar-60 avatar-borderd object-cover avatar-rounded img-fluid d-inline-block"
              />
              <Link to="/dashboard/app/bill-status">
                <div>
                  <h5 className="d-inline-block">Bill status</h5>

                  <small className="text-capitalize d-block">
                    Date Due - May 30
                  </small>
                </div>
              </Link>

              {/* <div className="d-flex align-items-center flex-shrink-0 gap-2">
        <button className="btn btn-primary-subtle p-1 lh-1">
          <i className="material-symbols-outlined font-size-14">
            add
          </i>
        </button>
        <button className="btn btn-danger-subtle p-1 lh-1">
          <i className="material-symbols-outlined font-size-14">
            close
          </i>
        </button>
      </div> */}
            </li>
          </ul>
        )}
      </Card.Body>
    </Card>
  );
}

export default RequestComp;
