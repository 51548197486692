import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import { useGetHomeownerListQuery } from "../../store/query/homeowner";

//profile-header
import ProfileHeader from "../../components/profile-header";

// image
import img1 from "../../assets/images/page-img/profile-bg2.jpg";
import img2 from "../../assets/images/page-img/profile-bg1.jpg";
import img3 from "../../assets/images/page-img/profile-bg3.jpg";
import img4 from "../../assets/images/page-img/profile-bg4.jpg";
import user05 from "../../assets/images/user/05.jpg";
import user06 from "../../assets/images/user/06.jpg";
import userPlaceholder from "../../assets/images/BE-logo.png";
import imgPlaceholder from "../../assets/images/BE-logo.png";

const apiUrl = process.env.REACT_APP_API_URL;

const Homeowners = () => {
  const { data } = useGetHomeownerListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const homeowners = data ?? [];

  return (
    <>
      <ProfileHeader title="Homeowner Lists" img={img3} />
      <div id="content-page" className="content-inner">
        <Container>
          <Row>
            {homeowners.length > 0 ? (
              homeowners.map((homeowner) => (
                <Col md={6}>
                  <Card className=" card-block card-stretch card-height">
                    <Card.Body className=" profile-page p-0">
                      <div className="profile-header-image">
                        <div className="cover-container" style={{ height: 50 }}>
                          {/* <img
                            loading="lazy"
                            src={homeowner.picture || img1}
                            alt={homeowner.house.houseName}
                            className="rounded img-fluid w-100"
                          /> */}
                        </div>
                        <div className="profile-info p-4">
                          <div className="user-detail">
                            <div className="d-flex flex-wrap justify-content-between align-items-start">
                              <div className="profile-detail d-flex">
                                <div className="profile-img pe-lg-4">
                                  <img
                                    loading="lazy"
                                    src={
                                      homeowner.house.picture || userPlaceholder
                                    }
                                    alt={`${homeowner.firstName} ${homeowner.lastName}`}
                                    className="avatar-130 img-fluid"
                                  />
                                </div>
                                <div className="user-data-block mt-md-0 mt-2">
                                  <h4>
                                    <Link to="/dashboard/app/hoa-profile">
                                      {homeowner.house.houseName}
                                    </Link>
                                  </h4>
                                  <h6>
                                    {homeowner.firstName} {homeowner.lastName}
                                  </h6>
                                  <p className="mb-2 mb-lg-0">
                                    {homeowner.emails[0]}
                                  </p>
                                </div>
                              </div>
                              <Link to="/dashboard/app/hoa-profile">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  View
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <Col>
                <p>No Homeowners Available.</p>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Homeowners;
