import React from "react";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useGetViolationTimelineQuery } from "../../../store/query/violations/violationsquery";
import { errorDisplayOrNavigate } from "../../../utilities/notificationToast";

const ViolationTimeline = () => {
  const { id } = useParams();
  const { data, isLoading, error } = useGetViolationTimelineQuery(Number(id));

  const timelineData = data;
  const loading = isLoading;

  if (error) {
    errorDisplayOrNavigate({ error, toastId: "timeline" });
  }

  const getDotClass = (description) => {
    if (description.toLowerCase().includes("in-progress")) {
      return "timeline-dots border-primary";
    }
    if (description.toLowerCase().includes("successfully sent")) {
      return "timeline-dots border-success";
    }
    if (description.toLowerCase().includes("commented")) {
      return "timeline-dots border-danger";
    }
    return "timeline-dots border-primary"; // Default
  };

  return (
    <div id="content-page" className="content-inner">
      <Container>
        {!loading && timelineData ? (
          <Row className="justify-content-center">
            <Col sm="12">
              <Card
                className="position-relative inner-page-bg bg-primary"
                style={{ height: "150px" }}
              >
                <div className="inner-page-title">
                  <h3 className="text-white">
                    Timeline Update for Violation Request
                  </h3>
                  <p className="text-white">{timelineData.description}</p>
                </div>
              </Card>
            </Col>
            <Col sm="12">
              <Card
                className="position-relative inner-page-bg"
                style={{ height: "150px" }}
              >
                <div className="inner-page-title">
                  <h3>{timelineData.name}</h3>
                  <br />
                  <Link to="/dashboard/app/violation-requests">
                    <Button className="my-2 ">Back</Button>
                  </Link>
                </div>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Timeline</h4>
                  </div>
                </Card.Header>
                <Card.Body className="pt-0">
                  <ul className="iq-timeline ms-1">
                    {timelineData.timeline.map((item, index) => (
                      <li key={index}>
                        <Link
                          to={`/dashboard/app/violation-requests/timeline/details/${id}`}
                        >
                          <div className={getDotClass(item.description)}></div>
                        </Link>
                        <div className="d-flex align-items-center justify-content-between">
                          <h6 className="mb-1">{item.title}</h6>
                          <small> {new Date(item.date).toLocaleString()}</small>
                        </div>
                        <div className="d-inline-block w-100">
                          <p>{item.description}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <p>Loading timeline data...</p>
        )}
      </Container>
    </div>
  );
};

export default ViolationTimeline;
