import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Tooltip,
  Card,
  Button,
  OverlayTrigger,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { IssuesOthersModel } from "../../../model/issues-others/issuesOthersModel";
import Pagination from "../../../components/pagination";

const IssuesOtherList = () => {
  const initData = [
    {
      dateCreated: "dummy data",
      dateResolved: "dummy data",
      reference: "dummy data",
      status: "dummy data",
      types: "dummy data",
    },
  ];
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const list = initData;

  const handleAddFormChanges = (event) => {
    event.preventDefault();
    const Reference = event.target.closest("tr").getAttribute("dataReference");
    const tdElem = event.target.closest("tr").querySelectorAll("td");
    const obj = {
      Reference: Reference,
    };
    Array.from(tdElem, (elem) => {
      if (elem.getAttribute("name") !== null) {
        obj[elem.getAttribute("name")] = elem.innerText;
      }
      return null;
    });
    const newArrIndex = list.findIndex((item) => {
      if (item.Reference === Reference) {
        return item;
      } else {
        return null;
      }
    });
    // Add[newArrIndex] = obj;
    // setAdd(Add);
  };

  return (
    <div Reference="content-page" className="content-inner">
      <Container>
        <Row>
          <Col sm="12">
            <Card
              className="position-relative inner-page-bg bg-primary"
              style={{ height: "150px" }}
            >
              <div className="inner-page-title">
                <h3 className="text-white">Issues & Other Requests</h3>
              </div>
            </Card>
          </Col>
          <Col sm="12">
            <Card>
              <div>
                <h3 className="card-header text-center font-weight-bold text-uppercase py-4">
                  Issues & Other Requests
                </h3>
              </div>
              <Card.Body className="pt-0">
                <div Reference="table" className="table-editable">
                  <table
                    className="table table-bordered table-responsive-md table-striped text-center"
                    style={{ overflowX: "auto" }}
                  >
                    <thead>
                      <tr>
                        <th>Reference</th>
                        <th>Type</th>
                        <th>Date Created </th>
                        <th>Date Closed</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((adds, props) => (
                        <tr key={props} dataReference={adds.Reference}>
                          <td
                            name="Reference"
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            onBlur={handleAddFormChanges}
                          >
                            {adds.reference}
                          </td>
                          <td
                            name="Type"
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            onBlur={handleAddFormChanges}
                          >
                            {adds.types}
                          </td>
                          <td
                            name="DateCreated"
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            onBlur={handleAddFormChanges}
                          >
                            {adds.dateCreated}
                          </td>

                          <td
                            name="DateClosed"
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            onBlur={handleAddFormChanges}
                          >
                            {adds.dateResolved}
                          </td>
                          <td
                            name="Status"
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            onBlur={handleAddFormChanges}
                          >
                            {adds.status}
                          </td>

                          <td>
                            <a onClick={() => {}} className="clickable" href="">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Update</Tooltip>}
                              >
                                <i className="material-symbols-outlined me-1 md-18 text-primary">
                                  assignment
                                </i>
                              </OverlayTrigger>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  page={page}
                  setPage={setPage}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  totalCount={list.length ?? 0}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default IssuesOtherList;
