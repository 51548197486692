// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-dots {
  bottom: -15px !important;
}

.slick-arrow::before {
  color: #50b5ff !important;
  opacity: 0.8 !important;
}

.slick-next {
  right: 0 !important;
}

.slick-prev {
  left: 0 !important;
  z-index: 10000 !important;
}

.slick-dots li button:before {
  color: #50b5ff !important;
  opacity: 0.3 !important;
}

.slick-dots li.slick-active button:before {
  color: #50b5ff !important;
  opacity: 1 !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/customSlick.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF;;AAEA;EACE,yBAAA;EACA,uBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,yBAAA;AACF;;AAEA;EACE,yBAAA;EACA,uBAAA;AACF;;AAEA;EACE,yBAAA;EACA,qBAAA;AACF","sourcesContent":[".slick-dots {\n  bottom: -15px !important;\n}\n\n.slick-arrow::before {\n  color: #50b5ff !important;\n  opacity: 0.8 !important;\n}\n\n.slick-next {\n  right: 0 !important;\n}\n\n.slick-prev {\n  left: 0 !important;\n  z-index: 10000 !important;\n}\n\n.slick-dots li button:before {\n  color: #50b5ff !important;\n  opacity: 0.3 !important;\n}\n\n.slick-dots li.slick-active button:before {\n  color: #50b5ff !important;\n  opacity: 1 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
