import { apiSlice, errorHandler, ErrorResponseModel } from "..";
import { ArcListModel, ArcSaveModel } from "../../../model/arc/arcModel";
import { arctags } from "../tags";

export const endpoints = {
  list: "/homeowner/arc/list",
  save: "/homeowner/arc/save",
};

export const arcSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getArcList: builder.query<ArcListModel, void>({
      query: () => ({
        url: endpoints.list,
        method: "GET",
      }),
      providesTags: [arctags[0]],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    saveArc: builder.mutation<void, ArcSaveModel>({
      query: (body) => ({
        url: endpoints.save,
        method: "POST",
        body,
      }),
      invalidatesTags: [arctags[0]],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const { useGetArcListQuery, useSaveArcMutation } = arcSlice;
