import React, { useState } from "react";
import Slider from "react-slick";

import { Col, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { NewsModel } from "../../model/news/newsmodel";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/scss/customSlick.scss";

const user4 = require("../../assets/images/user/04.jpg");

type Props = {
  data: NewsModel;
};

const NewsFeedItem = ({ data }: Props) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <span className="material-symbols-outlined">chevron_right</span>,
    prevArrow: <span className="material-symbols-outlined">chevron_left</span>,
    adaptiveHeight: true,
  };
  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });
  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }

  return (
    <Col sm={12} className="special-post">
      <div className="card card-block card-stretch card-height">
        <div className="card-body">
          <div className="user-post-data">
            <div className="d-flex justify-content-between align-items-center">
              <div className="me-3 flex-shrik-0">
                <img
                  className="border border-2 rounded-circle user-post-profile"
                  src={data?.postedBy?.picture ?? user4}
                  alt=""
                />
              </div>
              <div className="w-100">
                <div className=" d-flex align-items-center justify-content-between">
                  <div>
                    <h6 className="mb-0 d-inline-block">
                      {data?.postedBy?.name ?? "User"}
                    </h6>{" "}
                    <span className="d-inline-block text-primary">
                      <svg
                        className="align-text-bottom"
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.8457 0H4.34822C1.73547 0 0.0974121 1.84995 0.0974121 4.46789V11.5321C0.0974121 14.1501 1.72768 16 4.34822 16H11.8449C14.4663 16 16.0974 14.1501 16.0974 11.5321V4.46789C16.0974 1.84995 14.4663 0 11.8457 0Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M5.09741 7.99978L7.09797 9.9995L11.0974 6.00006"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </span>{" "}
                    <p className="mb-0 d-inline-block text-capitalize fw-medium">
                      {data.title}
                    </p>
                    <p className="mb-0">
                      <ReactTimeAgo
                        date={new Date(data.sentAt)}
                        locale="en-US"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <p className="mb-0">{data.description}</p>
            {/* <ul className="list-inline m-0 p-0 d-flex flex-wrap gap-1">
              <li>
                <Link to="#">#neighbours</Link>
              </li>
              <li>
                <Link to="#">#xmas</Link>
              </li>
              <li>
                <Link to="#">#boardevent</Link>
              </li>
              <li>
                <Link to="#">#together</Link>
              </li>
              <li>
                <Link to="#">#celebration</Link>
              </li>
            </ul> */}
          </div>
          <div className="user-post mt-4">
            {data.documents.length > 1 ? (
              <Slider {...settings}>
                {data.documents.map((doc, docKey) => (
                  <div key={docKey}>
                    <img
                      src={doc.file}
                      alt="post-images"
                      className="img-fluid rounded w-100"
                      loading="lazy"
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              data.documents.map((doc, docKey) => (
                <div key={docKey}>
                  <img
                    src={doc.file}
                    alt="post-images"
                    className="img-fluid rounded w-100"
                    loading="lazy"
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default NewsFeedItem;
