import { apiSlice, errorHandler, ErrorResponseModel } from "..";
import { VotingListModel } from "../../../model/voting/votingmodel";
import { votingTags } from "../tags";

export const endpoints = {
  list: "/homeowner/voting/list",
  saveVote: "/homeowner/voting/save",
};

export const votingSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVotingList: builder.query<VotingListModel[], void>({
      query: () => ({
        url: endpoints.list,
        method: "GET",
      }),
      providesTags: [votingTags[0]],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    saveVote: builder.mutation<{ message: string }, { candidateId: number }>({
      query: (body) => ({
        url: endpoints.saveVote,
        method: "POST",
        body,
      }),
      invalidatesTags: [votingTags[0]],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const { useGetVotingListQuery, useSaveVoteMutation } = votingSlice;
