import React, { useEffect } from "react";
import { useGetViolationCommentsQuery } from "../../../store/query/violations/violationsquery";
import useTableParameters from "../../../hooks/useTableParameters";
import { Button, Col, Row } from "react-bootstrap";
import { errorDisplayOrNavigate } from "../../../utilities/notificationToast";
import RichTextfield from "../../../components/RichTextfield";

type Props = {
  id: number;
};

const ViolationHistory = ({ id }: Props) => {
  const { page, entries, handleChangePage } = useTableParameters(false);
  const {
    data: commentsData,
    isLoading: commentsLoading,
    error,
    refetch,
  } = useGetViolationCommentsQuery({ id: Number(id), page: page - 1, entries });

  const comments = commentsData?.comments ?? [];
  const count = commentsData?.count ?? 0;

  const getTotalPages = (): number => {
    if (count === 0) return 0;

    const divided = commentsData.count / entries;

    if (!Number.isInteger(divided)) {
      console.log("trunc");
      return Math.trunc(divided) + 1;
    }

    return divided;
  };

  if (error) {
    errorDisplayOrNavigate({ error, toastId: "comment-errro" });
  }

  useEffect(() => {
    refetch();
  }, [page]);

  return (
    <Col>
      {commentsLoading ? (
        <p>Loading History</p>
      ) : (
        <>
          <p>{comments.length > 0 ? "History" : "No History"}</p>
          {comments.length > 0 && (
            <>
              <Row>
                <ul className="list-inline ms-1">
                  {comments.map((comment) => (
                    <li key={comment.id} className="pt-4">
                      <div className="d-flex align-items-start ">
                        <div className="avatar px-2">
                          <img
                            src={comment.picture}
                            alt={`${comment.name}'s profile`}
                            className="avatar-circle bg-warning text-white"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                            }}
                          />{" "}
                        </div>
                        <div className="ms-1">
                          <div className="d-flex text-nowrap">
                            <h6>{comment.name}</h6>
                            <small className="px-5 mx-5">
                              {new Date(comment.createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "2-digit",
                                }
                              )}
                            </small>
                          </div>
                          <RichTextfield isRead value={comment.comment} />
                          {comment.documents &&
                            comment.documents.length > 0 && (
                              <ul className="list-inline">
                                {comment.documents.map((doc) => (
                                  <li key={doc.id}>
                                    <a
                                      href={doc.file}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {doc.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </Row>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <Button
                  onClick={() => handleChangePage(false)}
                  disabled={page === 1}
                >
                  Previous
                </Button>
                <span>
                  Page {page} of {getTotalPages()}
                </span>
                <Button
                  onClick={() => handleChangePage(true)}
                  disabled={page === getTotalPages()}
                >
                  Next
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </Col>
  );
};

export default ViolationHistory;
