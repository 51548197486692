import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { json, Link, useNavigate } from "react-router-dom";
import { useGetApiClient } from "../../../api/useApiClient";
import { event } from "jquery";
import { useGetViolationListQuery } from "../../../store/query/violations/violationsquery";

const IssuesViolation = () => {
  const initData = [
    {
      id: 0,
      Reference: "------",
      CreatedBy: "------",
      Type: "------",
      Description: "------",
      DateCreated: "------",
      DateClosed: "------",
      Status: "------",
    },
  ];
  const navigate = useNavigate();

  const { data } = useGetViolationListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const list = data
    ? data.violationReports.map((reports) => ({
        id: reports.id,
        Reference: reports.referenceNumber,
        CreatedBy: reports.createdBy,
        Type: reports.type,
        Description: reports.description,
        DateCreated: reports.createdAt,
        DateClosed: reports.dateClosed,
        Status: reports.status,
      }))
    : [initData];

  const handleAddFormChanges = (event) => {
    event.preventDefault();
    const Reference = event.target.closest("tr").getAttribute("dataReference");
    const tdElem = event.target.closest("tr").querySelectorAll("td");
    const obj = {
      Reference: Reference,
    };
    Array.from(tdElem, (elem) => {
      if (elem.getAttribute("name") !== null) {
        obj[elem.getAttribute("name")] = elem.innerText;
      }
      return null;
    });
    const newArrIndex = list.findIndex((item) => {
      if (item.Reference === Reference) {
        return item;
      } else {
        return null;
      }
    });
    // Add[newArrIndex] = obj;
    // setAdd(Add);
  };

  const handleViolationUpdateClick = (id) => {
    localStorage.setItem("selectedViolationId", id);
    navigate(`/dashboard/app/violation-requests/timeline/${id}`);
  };

  return (
    <>
      <div Reference="content-page" className="content-inner">
        <Container>
          <Row>
            <Col sm="12">
              <Card
                className="position-relative inner-page-bg bg-primary"
                style={{ height: "150px" }}
              >
                <div className="inner-page-title">
                  <h3 className="text-white">Violation List</h3>
                </div>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <div>
                  <h3 className="card-header text-center font-weight-bold text-uppercase py-4">
                    Violation list
                  </h3>
                </div>
                <Card.Body className="pt-0">
                  <div Reference="table" className="table-editable">
                    <span className="table-add float-end mb-3 me-2">
                      <Link to="/dashboard/app/violation-requests/create">
                        <Button className="btn btn-sm btn-success d-flex align-items-center">
                          <i className="material-symbols-outlined me-1 md-18">
                            add
                          </i>
                          Create New
                        </Button>
                      </Link>
                    </span>
                    <table
                      className="table table-bordered table-responsive-md table-striped text-center"
                      style={{ overflowX: "auto" }}
                    >
                      <thead>
                        <tr>
                          <th>Reference</th>
                          <th>Created by</th>
                          <th>Type</th>
                          <th>Description</th>
                          <th>Date Created </th>
                          <th>Date Closed</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list.map((adds, props) => (
                          <tr key={props} dataReference={adds.Reference}>
                            <td
                              name="Reference"
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onBlur={handleAddFormChanges}
                            >
                              {adds.Reference}
                            </td>
                            <td
                              name="CreatedBy"
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onBlur={handleAddFormChanges}
                            >
                              {adds.CreatedBy}
                            </td>
                            <td
                              name="Type"
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onBlur={handleAddFormChanges}
                            >
                              {adds.Type}
                            </td>
                            <td
                              name="Description"
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onBlur={handleAddFormChanges}
                            >
                              {adds.Description}
                            </td>
                            <td
                              name="DateCreated"
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onBlur={handleAddFormChanges}
                            >
                              {adds.DateCreated}
                            </td>

                            <td
                              name="DateClosed"
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onBlur={handleAddFormChanges}
                            >
                              {adds.DateClosed}
                            </td>
                            <td
                              name="Status"
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onBlur={handleAddFormChanges}
                            >
                              {adds.Status}
                            </td>

                            <td>
                              <a
                                onClick={() =>
                                  handleViolationUpdateClick(adds.id)
                                }
                                className="clickable"
                                href=""
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Update</Tooltip>}
                                >
                                  <i className="material-symbols-outlined me-1 md-18 text-primary">
                                    assignment
                                  </i>
                                </OverlayTrigger>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default IssuesViolation;
