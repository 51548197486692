import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
  Row,
  Col,
  Container,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Collapse,
  ProgressBar,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import CreatePost from "../../components/create-post";

//image
import user1 from "../../assets/images/user/1.jpg";
import user01 from "../../assets/images/user/01.jpg";
import user2 from "../../assets/images/user/02.jpg";
import user3 from "../../assets/images/user/03.jpg";
import user4 from "../../assets/images/user/04.jpg";
import user13 from "../../assets/images/user/05.jpg";
import user14 from "../../assets/images/user/06.jpg";
import user15 from "../../assets/images/user/07.jpg";
import user16 from "../../assets/images/user/08.jpg";
import user5 from "../../assets/images/page-img/fun.jpg";
import user6 from "../../assets/images/user/13.jpg";
import user7 from "../../assets/images/user/17.jpg";
import user8 from "../../assets/images/user/16.jpg";
import user9 from "../../assets/images/user/09.jpg";
import user10 from "../../assets/images/user/10.jpg";
import user11 from "../../assets/images/user/14.jpg";
import user12 from "../../assets/images/user/15.jpg";
import profileBgImg from "../../assets/images/page-img/profile-bg9.jpg";

import icon1 from "../../assets/images/icon/01.png";
import icon2 from "../../assets/images/icon/02.png";
import icon3 from "../../assets/images/icon/03.png";
import icon4 from "../../assets/images/icon/04.png";
import icon5 from "../../assets/images/icon/05.png";
import icon6 from "../../assets/images/icon/06.png";
import icon7 from "../../assets/images/icon/07.png";
import loader from "../../assets/images/page-img/page-load-loader.gif";
import boyImg from "../../assets/images/page-img/boy.webp";
import busImg from "../../assets/images/page-img/bus.webp";
import img11 from "../../assets/images/page-img/fd.webp";
import mountain from "../../assets/images/page-img/mountain.webp";
import pizza from "../../assets/images/page-img/pizza.webp";
import bootstrapImg from "../../assets/images/icon/bootstrap-5.png";
import adsImg from "../../assets/images/page-img/ads.jpg";
import arcReq from "../../assets/images/user/arc-request.jpg";
import violation from "../../assets/images/user/violation.jpg";
import billStatus from "../../assets/images/user/statement.jpg";

// FsLightbox
import ReactFsLightbox from "fslightbox-react";

// Share-offcanvas
import ShareOffcanvasNew from "../../components/ShareOffcanvasNew";
import TempVotingComp from "./TempVotingComp";
import { useGetBlogListQuery } from "../../store/query/blog/blogquery";
import { errorDisplayOrNavigate } from "../../utilities/notificationToast";
import BlogFeedItem from "./BlogFeedItem";
import { useGetNewsListQuery } from "../../store/query/news/newsquery";
import NewsFeedItem from "./NewsFeedItem";
import RequestComp from "./RequestComp";
import { useGetEventListQuery } from "../../store/query/event/eventquery";
import { useGetViolationListQuery } from "../../store/query/violations/violationsquery";
import EventFeedItem from "./EventFeedItem";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useGetVotingListQuery } from "../../store/query/voting/votingquery";

const newsSlickSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const FsLightbox = ReactFsLightbox.default
  ? ReactFsLightbox.default
  : ReactFsLightbox;

const beneventoPostFromLocal = sessionStorage.getItem("benevento-posts");

// import img from '../assets/images/user/1.jpg'

const Index = () => {
  const {
    data: blogData,
    isLoading: blogLoading,
    error: blogError,
  } = useGetBlogListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: newsData,
    isLoading: newsLoading,
    error: newsError,
  } = useGetNewsListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: eventsData,
    isLoading: eventsLoading,
    error: eventsError,
  } = useGetEventListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: votingData,
    isLoading: votingLoading,
    error: votingError,
  } = useGetVotingListQuery(undefined, { refetchOnMountOrArgChange: true });

  const navigate = useNavigate();
  const loading = blogLoading || newsLoading || eventsLoading || votingLoading;
  const finalLoad = beneventoPostFromLocal ? false : loading;
  const dataFromLocal = beneventoPostFromLocal
    ? JSON.parse(beneventoPostFromLocal)
    : [];
  const [loadContent, setLoadContent] = useState(true);
  const [allData, setAllData] = useState([]);

  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });
  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }

  function sortDate(a, b) {
    const date1 = new Date(a).getTime();
    const date2 = new Date(b).getTime();
    if (date2 < date1) {
      return -1;
    }

    if (date2 > date1) {
      return 1;
    }

    return 0;
  }

  if (blogError) {
    errorDisplayOrNavigate({ error: blogError, toastId: "blog-error" });
  }

  if (newsError) {
    errorDisplayOrNavigate({ error: newsError, toastId: "news-error" });
  }

  if (eventsError) {
    errorDisplayOrNavigate({ error: eventsError, toastId: "events-error" });
  }

  if (votingError) {
    errorDisplayOrNavigate({ error: votingError, toastId: "voting-error" });
  }

  useEffect(() => {
    const newsDataIns = newsData
      ? newsData.map((item) => {
          const date = new Date(`${item.sentAt} UTC`).toLocaleString("en-us");

          return {
            category: "news",
            data: { ...item, sentAt: date },
            postedAt: date,
          };
        })
      : [];

    const eventsDataIns = eventsData
      ? eventsData.map((item) => ({
          category: "events",
          data: {
            ...item,
            createdAt: new Date(`${item.createdAt} UTC`).toLocaleString(
              "en-us"
            ),
          },
          postedAt: new Date(`${item.createdAt} UTC`).toLocaleString("en-us"),
        }))
      : [];
    const blogDataIns = blogData
      ? blogData.map((item) => ({
          category: "blog",
          data: {
            ...item,
            sentAt: new Date(`${item.sentAt} UTC`).toLocaleString("en-us"),
          },
          postedAt: new Date(`${item.sentAt} UTC`).toLocaleString("en-us"),
        }))
      : [];

    const votingDataIns = votingData
      ? votingData.map((item) => ({
          category: "voting",
          data: {
            ...item,
            sentAt: new Date(`${item.createdAt} UTC`).toLocaleString("en-us"),
          },
          postedAt: new Date(`${item.createdAt} UTC`).toLocaleString("en-us"),
        }))
      : [];

    let datas = [
      ...newsDataIns,
      ...eventsDataIns,
      ...blogDataIns,
      ...votingDataIns,
    ];

    if (dataFromLocal.length > 0) {
      const dataFromLocalID = dataFromLocal.map(
        (item) => `${item.category}-${item.data.id}`
      );

      const filteredNewEntries = datas.filter(
        (item) => !dataFromLocalID.includes(`${item.category}-${item.data.id}`)
      );

      if (filteredNewEntries.length > 0) {
        // insert here
        const newEntries = [...filteredNewEntries, ...dataFromLocal];
        let newDatas = [];
        newEntries.forEach((item, index) => {
          if (index < 10) {
            newDatas.push(item);
          }
        });

        sessionStorage.setItem("benevento-posts", JSON.stringify(newDatas));
        setAllData(newDatas.sort((a, b) => sortDate(a.postedAt, b.postedAt)));
      } else {
        let newDatas = [];
        datas.forEach((item, index) => {
          if (index < 10) {
            newDatas.push(item);
          }
        });

        sessionStorage.setItem("benevento-posts", JSON.stringify(newDatas));
        setAllData(newDatas.sort((a, b) => sortDate(a.postedAt, b.postedAt)));
      }
    } else if (datas.length > 0) {
      let newDatas = [];
      datas.forEach((item, index) => {
        if (index < 10) {
          newDatas.push(item);
        }
      });

      sessionStorage.setItem("benevento-posts", JSON.stringify(newDatas));
      setAllData(newDatas.sort((a, b) => sortDate(a.postedAt, b.postedAt)));
    } else {
      setAllData(dataFromLocal);
    }
  }, [newsData, eventsData, blogData, votingData]);

  useEffect(() => {
    function handleScroll() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        setTimeout(() => {
          setLoadContent(false);
        }, 2000);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div id="content-page" className="content-inner">
        <FsLightbox
          toggler={imageController.toggler}
          sources={[user5, boyImg, busImg, img11, mountain, pizza]}
          slide={imageController.slide}
        />
        <Container>
          <Row className="gx-4">
            <Col lg={8}>
              <div id="content">
                <Row>
                  <Col sm={12}></Col>
                </Row>
                {finalLoad ? (
                  <div className="col-sm-12 text-center">
                    <img
                      src={loader}
                      alt="loader"
                      style={{ height: "100px" }}
                    />
                  </div>
                ) : (
                  <>
                    <Row>
                      <Col sm={12}>
                        <CreatePost className="card-block card-stretch card-height" />
                      </Col>
                    </Row>
                    <Row className="special-post-container">
                      {allData.length > 0 ? (
                        allData.map((item, key) => {
                          if (item.category === "news") {
                            return <NewsFeedItem key={key} data={item.data} />;
                          } else if (item.category === "events") {
                            return <EventFeedItem data={item.data} key={key} />;
                          } else if (item.category === "blog") {
                            return <BlogFeedItem key={key} data={item.data} />;
                          } else if (item.category === "voting") {
                            return (
                              <TempVotingComp
                                data={item.data}
                                isLoading={votingLoading}
                                index={key}
                              />
                            );
                          }
                        })
                      ) : (
                        <p>No available feeds..</p>
                      )}
                      {/* {newsData &&
                        newsData.map((item, keyItem) => (
                          <NewsFeedItem key={keyItem} data={item} />
                        ))} */}
                      {/* {newsData && (
                        <Slider {...newsSlickSettings}>
                          {newsData.map((item, keyItem) => (
                            <NewsFeedItem key={keyItem} data={item} />
                          ))}
                        </Slider>
                      )} */}
                      {/* {blogData &&
                        blogData.map((item, keyItem) => (
                          <BlogFeedItem key={keyItem} data={item} />
                        ))} */}

                      {/* {eventsData &&
                        eventsData.map((item, keyItem) => (
                          <EventFeedItem data={item} key={keyItem} />
                        ))} */}
                    </Row>
                  </>
                )}
              </div>
            </Col>
            <Col lg={4} className="position-sticky">
              <RequestComp />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;
