import React from "react";
import {
  Container,
  Row,
  Col,
  Tooltip,
  Card,
  Button,
  OverlayTrigger,
  Stack,
} from "react-bootstrap";
import { useGetBillingListQuery } from "../../../store/query/billing/billingQuery";
import { errorDisplayOrNavigate } from "../../../utilities/notificationToast";

const loader = require("../../../assets//images/page-img/page-load-loader.gif");

type Props = {};

const BillingList = (props: Props) => {
  const {
    data: billingData,
    isLoading: billingLoading,
    error: billingError,
  } = useGetBillingListQuery();

  console.log(billingData);

  const data = billingData ? billingData : [];

  const list = data ?? [];
  const loading = billingLoading;

  if (billingError) {
    errorDisplayOrNavigate({
      error: billingError,
      toastId: "billing-error",
    });
  }

  return (
    <div className="content-inner">
      <Container>
        <Row>
          <Col sm={12}>
            <Card
              className="position-relative inner-page-bg bg-primary"
              style={{ height: "150px" }}
            >
              <div className="inner-page-title">
                <h3 className="text-white">Monthly Bills History</h3>
              </div>
            </Card>
          </Col>
          <Col sm="12">
            <Card>
              <div>
                <h3 className="card-header text-center font-weight-bold text-uppercase py-4">
                  Bill Status
                </h3>
              </div>
              <Card.Body className="pt-0">
                <div className="table-editable">
                  <table className="table table-bordered table-responsive-md table-striped text-center">
                    <thead>
                      <tr>
                        <th>Control #</th>
                        <th>Description</th>
                        <th>Date Issued</th>
                        <th>Date Due</th>
                        <th>Prev Bal</th>
                        <th>Amount</th>
                        <th>Paid</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={7}>
                            <div className="col-sm-12 text-center">
                              <img
                                src={loader}
                                alt="loader"
                                style={{ height: "100px" }}
                              />
                            </div>
                          </td>
                        </tr>
                      ) : list.length === 0 ? (
                        <tr>
                          <td colSpan={7}>
                            <div className="col-sm-12 text-center">
                              <p>No billings available</p>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        list.map((item, idx) => (
                          <tr key={idx}>
                            <td>{item.controlNumber}</td>
                            <td>{item.description}</td>
                            <td>{item.dateIssued}</td>
                            <td>{item.dateDue}</td>
                            <td>{item.prevBal}</td>
                            <td>{item.prevBal}</td>
                            <td>{item.amountPaid}</td>
                            <td>{item.status}</td>

                            <td>
                              <a href="">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Pay</Tooltip>}
                                >
                                  <i className="material-symbols-outlined me-1 md-18 text-primary">
                                    paid
                                  </i>
                                </OverlayTrigger>{" "}
                              </a>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BillingList;
