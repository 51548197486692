import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getApiErrorStatus } from "../store/query";

export interface ToastErrorType {
  text?: string;
  error?: FetchBaseQueryError | SerializedError;
  toastId?: string | number;
}

export const errorDisplayOrNavigate = ({
  error,
  text,
  toastId,
}: ToastErrorType): void => {
  const errorStatus = getApiErrorStatus(error);

  switch (errorStatus) {
    // case 401:
    //   window.location.href = fourOOneRoute;
    //   break;
    case 500:
      window.location.href = "errors/error500";
      break;
    default:
      showToastError({ error, text, toastId });
      break;
  }
};

export const showToastSuccess = ({
  text,
  toastId,
}: {
  text: string;
  toastId: string;
}) => {
  return toast(text, {
    position: "top-right",
    type: "success",
    autoClose: 5000,
    toastId,
  });
};

export const showToastInfo = ({ text, toastId }) => {
  return toast(text, {
    position: "top-right",
    type: "info",
    autoClose: 5000,
    toastId,
  });
};

export const showToastError = ({ error, text, toastId }: ToastErrorType) => {
  return toast(
    error
      ? "status" in error
        ? "error" in error
          ? error.error
          : JSON.stringify(error.data).replace(/^"(.*)"$/, "$1")
        : error.message
      : text,
    {
      position: "top-right",
      type: "error",
      autoClose: 5000,
      toastId,
    }
  );
};
