import { apiSlice, errorHandler, ErrorResponseModel } from "..";
import { CalendarModel } from "../../../model/calendar/calendarModel";

const endpoints = {
  list: (start: string, end: string) =>
    `/homeowner/profiles/calendar/get?from=${start}&to=${end}`,
};

export const calendarSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCalendarItems: builder.query<
      CalendarModel[],
      {
        start: string;
        end: string;
      }
    >({
      query: ({ start, end }) => ({
        url: endpoints.list(start, end),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const { useGetCalendarItemsQuery } = calendarSlice;
