import { apiSlice, errorHandler, ErrorResponseModel } from "..";
import { BillingModel } from "../../../model/billing/billingModel";

const endpoints = {
  list: "/homeowner/billing/list",
};

export const billingSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBillingList: builder.query<BillingModel[], void>({
      query: () => ({
        url: endpoints.list,
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const { useGetBillingListQuery } = billingSlice;
