import React, { useEffect } from "react";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { saveAs } from "file-saver";
import _ from "lodash";
import UploadFile from "../../../components/UploadFile";
import useFileView from "../../../hooks/useFileView";
import FileViewModal from "../../../components/FileViewModal";

type Props = {
  formDocs: { name: string; file: string }[];
  values: { name: string; file: string; parentName: number }[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string | undefined) => void;
  errorForm: string;
};

const DocumentsDownload = ({
  formDocs,
  values,
  setFieldError,
  setFieldValue,
  errorForm,
}: Props) => {
  const [viewDoc, handleToggleDoc] = useFileView();

  useEffect(() => {
    setFieldError("forms", "");
  }, []);

  return (
    <>
      {viewDoc.open && (
        <FileViewModal doc={viewDoc} handleToggleView={handleToggleDoc} />
      )}
      <div className="form-card text-start">
        <Row>
          <Col md={6}>
            <h3>Documents for Download:</h3>
            {formDocs.map((doc, key) => (
              <Button
                key={key}
                variant="danger"
                className="mx-2 my-4"
                onClick={() => saveAs(doc.file, doc.name)}
              >
                {doc.name}
              </Button>
            ))}
          </Col>
          <Col md={12}>
            {formDocs.map((docForm, key) => (
              <React.Fragment key={key}>
                <Form.Group className="form-group">
                  <Form.Label>{docForm.name}</Form.Label>
                  <UploadFile<{ name: string; file: string }[]>
                    name="forms"
                    errorForm={errorForm}
                    loading={false}
                    setFieldError={setFieldError}
                    setFieldValue={setFieldValue}
                    isInvalid={!_.isEmpty(errorForm)}
                    docData={values}
                    otherField={{ key: "parentName", value: key }}
                  />
                </Form.Group>
                {values
                  .filter((item) => item.parentName === key)
                  .map((doc, i) => (
                    <div key={i}>
                      <Card
                        onClick={() =>
                          handleToggleDoc(
                            true,
                            `${process.env.REACT_APP_MAIN_URL}${doc.file}`,
                            doc.name
                          )
                        }
                        style={{
                          background: "#f1f1f1",
                          cursor: "pointer",
                        }}
                      >
                        <Card.Body
                          style={{
                            padding: "10px 15px",
                            color: "#7e8b9a",
                          }}
                        >
                          {doc.name}
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
              </React.Fragment>
            ))}
          </Col>
          <Col md={6}></Col>
        </Row>
      </div>
    </>
  );
};

export default DocumentsDownload;
