import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Container, Card } from "react-bootstrap";
import { useGetApiClient } from "../../../api/useApiClient";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import {
  useGetViolationCommentsQuery,
  useGetViolationTimelineDetailsQuery,
} from "../../../store/query/violations/violationsquery";
import useTableParameters from "../../../hooks/useTableParameters";
import { errorDisplayOrNavigate } from "../../../utilities/notificationToast";
import ViolationComments from "./ViolationComments";
import ViolationHistory from "./ViolationHistory";
import ViolationDetailForm from "./ViolationDetailForm";

const ViolationDetails = () => {
  const { id } = useParams();

  const { data: detailsData, error: detailsError } =
    useGetViolationTimelineDetailsQuery(Number(id));

  const detail = detailsData ?? null;

  if (detailsError) {
    errorDisplayOrNavigate({ error: detailsError, toastId: "details-error" });
  }

  return (
    <>
      <div id="content-page" className="content-inner">
        <Container>
          {detail ? (
            <Row>
              <Col sm="12">
                <Card
                  className="position-relative inner-page-bg bg-primary"
                  style={{ height: "150px" }}
                >
                  <div className="inner-page-title">
                    <h3 className="text-white">Violation</h3>
                    <p className="text-white">{detail?.name || ""}</p>
                  </div>
                </Card>
              </Col>
              <Col sm="12" lg="6">
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">
                        Tracking Number: {detail?.referenceNumber || ""}
                      </h4>
                    </div>
                  </Card.Header>
                  <Card.Body className="pt-0">
                    <Row className="form-group">
                      <Col>
                        <Form.Group className="form-group">
                          <Form.Label> Violation Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            disabled
                            value={detail?.description || ""}
                          />
                        </Form.Group>
                      </Col>
                      <hr />
                      <ViolationComments id={detail.id} />
                      <hr />
                      <ViolationHistory id={detail.id} />

                      <Col></Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="12" lg="6">
                <ViolationDetailForm details={detailsData} />
              </Col>
            </Row>
          ) : (
            <p>Loading...</p>
          )}
        </Container>
        <ToastContainer />
      </div>
    </>
  );
};

export default ViolationDetails;
