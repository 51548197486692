import React, { useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Webcam from "react-webcam";
import { errorDisplayOrNavigate } from "../utilities/notificationToast";
import { useUploadFileMutation } from "../store/query";

type DocType = { name: string; file: string }[];

interface Props<T extends DocType> {
  open: boolean;
  handleToggle: () => void;
  title?: string;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError?: (field: string, message: string | undefined) => void;
  name: string;
  docData: T;
}

const CameraModal = <T extends DocType>({
  open,
  handleToggle,
  title = "Take a Snap",
  setFieldError,
  setFieldValue,
  name,
  docData,
}: Props<T>) => {
  const [uploadMutate, { isLoading, reset, error, isSuccess }] =
    useUploadFileMutation();
  const webcamRef = useRef<Webcam>();
  const [facingMode, setFacingMode] = useState<string>("environment");

  const handleFacingMode = () =>
    setFacingMode((prev) => (prev === "environment" ? "user" : "environment"));

  const handleCaptureImage = async () => {
    try {
      const camBase64 = webcamRef.current.getScreenshot();
      const byteString = atob(camBase64.split(",")[1]);
      const mimeString = camBase64.split(",")[0].split(":")[1].split(";")[0];
      const buffer = new Uint8Array(byteString.length);

      for (let i = 0; i < byteString.length; i++) {
        buffer[i] = byteString.charCodeAt(i);
      }
      const newDate = new Date();

      const captureName = `captured-image-${
        newDate.getMonth() + 1
      }${newDate.getDate()}${newDate.getFullYear()}-${newDate.getHours() + 1}${
        newDate.getMinutes() + 1
      }${newDate.getSeconds() + 1}.jpg`;
      const blob = new Blob([buffer], { type: mimeString });
      const binary = new File([blob], captureName, {
        type: mimeString,
      });

      const data = await uploadMutate({
        endpoint: "/homeowner/arc/upload/document",
        file: binary,
        formKey: "file",
      }).unwrap();

      if (data) {
        setFieldValue(
          name,
          docData && docData.length > 0
            ? [...docData, { name: captureName, file: data.directoryPath }]
            : [{ name: captureName, file: data.directoryPath }],
          false
        );
        setFieldError(name, "");

        handleToggle();
      }
    } catch (err) {
      errorDisplayOrNavigate({
        text: "Error taking a picture.",
        toastId: "capture-image",
      });
    }
  };

  if (isSuccess) {
    reset();
  }

  if (error) {
    reset();

    errorDisplayOrNavigate({ error, toastId: "upload-error" });
  }

  return (
    <Modal
      show={open}
      onHide={handleToggle}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "100%", overflowY: "auto" }}>
        <Webcam
          ref={webcamRef}
          audio={false}
          screenshotFormat="image/jpeg"
          width={"100%"}
          videoConstraints={{
            width: 1280,
            height: 720,
            facingMode: facingMode,
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            type="button"
            variant="secondary"
            className="d-inline-flex mb-1 me-1"
            onClick={handleFacingMode}
            disabled={isLoading}
          >
            <i className="material-symbols-outlined">flip_camera_android</i>
          </Button>

          <Button
            type="button"
            variant="primary"
            className="d-inline-flex mb-1 me-1"
            onClick={handleCaptureImage}
            disabled={isLoading}
          >
            <i className="material-symbols-outlined me-0">photo_camera</i>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CameraModal;
