import { apiSlice, errorHandler, ErrorResponseModel } from "..";
import { dashboardtags } from "../tags";
import { DashboardRequestCountModel } from "../../../model/dashboard/dashboardmodel";

const endpoints = {
  "request-count": "/homeowner/dashboard/get",
};

export const dashboardSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRequestCount: builder.query<DashboardRequestCountModel, void>({
      query: () => ({
        url: endpoints["request-count"],
        method: "GET",
      }),
      providesTags: (res, err, arg) => [dashboardtags[0]],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const { useGetRequestCountQuery } = dashboardSlice;
