import { apiSlice, errorHandler, ErrorResponseModel } from "..";
import { eventTags } from "../tags";
import {
  EventModel,
  EventSaveCommentBody,
  SaveAttendeeBody,
  SavePledgeBody,
} from "../../../model/event/eventModel";

const endpoints = {
  list: "/general/event/get",
  saveComment: "/general/event/comment/save",
  saveAttendee: "/general/event/attendee/save",
  savePledge: "/general/event/pledge/save",
};

export const eventSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEventList: builder.query<EventModel[], void>({
      query: () => ({
        url: endpoints.list,
        method: "GET",
      }),
      providesTags: (res, err, arg) => [eventTags[0]],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    saveEventComment: builder.mutation<void, EventSaveCommentBody>({
      query: (body) => ({
        url: endpoints.saveComment,
        method: "POST",
        body,
      }),
      invalidatesTags: (res, err, arg) => [eventTags[0]],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    saveEventAttendee: builder.mutation<void, SaveAttendeeBody>({
      query: (body) => ({
        url: endpoints.saveAttendee,
        method: "POST",
        body,
      }),
      invalidatesTags: (res, err, arg) => [eventTags[0]],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    saveEventPledge: builder.mutation<void, SavePledgeBody>({
      query: (body) => ({
        url: endpoints.savePledge,
        method: "POST",
        body,
      }),
      invalidatesTags: (res, err, arg) => [eventTags[0]],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const {
  useGetEventListQuery,
  useSaveEventAttendeeMutation,
  useSaveEventCommentMutation,
  useSaveEventPledgeMutation,
} = eventSlice;
