import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import _ from "lodash";
import { useFormik } from "formik";
import DatesForm from "./DatesForm";
import DescriptionRequest from "./DescriptionRequest";
import DocumentsDownload from "./DocumentsDownload";
import { useSaveArcMutation } from "../../../store/query/arc/arcQuery";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utilities/notificationToast";
import { ArcSaveModel } from "../../../model/arc/arcModel";

const image1 = require("../../../assets/images/page-img/img-success.png");

type Props = {};

interface FormModel {
  name: string;
  arcId: number | null;
  reconsiderations: string[];
  forms: { name: string; file: string }[];
  description: string;
  dateCreated: string;
  dateClosed: string;
  dateEnd: string;
  status: string;
}

const CreateArc = (props: Props) => {
  const [show, setShow] = useState(1);
  const [reconsiderationOptions, setReconsiderationOptions] = useState<
    string[]
  >([]);
  const [formDocs, setFormDocs] = useState<{ name: string; file: string }[]>(
    []
  );
  const [
    saveArc,
    {
      reset: saveReset,
      error: saveError,
      isSuccess: saveSuccess,
      isLoading: saveLoading,
    },
  ] = useSaveArcMutation();
  const formSchema = Yup.object().shape({
    name: Yup.string(),
    arcId: Yup.number().required("Please select an ARC Type"),
    reconsiderations: Yup.array()
      .of(Yup.string())
      .min(1, "Please select at least one."),
    forms: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string(),
          file: Yup.string(),
          parentName: Yup.number(),
        })
      )
      .min(1, "Please provide a document."),
    description: Yup.string().required("This field is required."),
    material: Yup.string().required("This field is required."),
    dateCreated: Yup.date().required("Please provide a date."),
    dateClosed: Yup.date().required("Please provide a date."),
    dateEnd: Yup.date().required("Please provide a date."),
    status: Yup.string(),
  });

  const submitFormFn = async (form: ArcSaveModel) => {
    await saveArc(form);
    setShow(4);
  };

  const {
    setFieldError,
    setFieldValue,
    values,
    errors,
    handleSubmit,
    validateForm,
    resetForm,
  } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema: formSchema,
    initialValues: {
      name: "",
      arcId: null,
      reconsiderations: [],
      forms: [],
      description: "",
      material: "",
      dateCreated: new Date().toISOString().split("T")[0],
      dateClosed: new Date().toISOString().split("T")[0],
      dateEnd: new Date().toISOString().split("T")[0],
      status: "",
    },
    onSubmit: (values) => {
      const form: ArcSaveModel = {
        arcId: Number(values.arcId),
        closingDate: values.dateClosed,
        description: values.description,
        documents: values.forms.map((item) => ({
          file: item.file,
          name: item.name,
        })),
        endDate: values.dateEnd,
        materials: values.material,
        reconsiderations: reconsiderationOptions.map((item) => {
          const answer = values.reconsiderations.includes(item);
          return {
            question: item,
            answer,
          };
        }),
        startDate: values.dateCreated,
      };
      submitFormFn(form);
      resetForm({
        values: {
          name: "",
          arcId: null,
          reconsiderations: [],
          forms: [],
          description: "",
          material: "",
          dateCreated: new Date().toISOString().split("T")[0],
          dateClosed: new Date().toISOString().split("T")[0],
          dateEnd: new Date().toISOString().split("T")[0],
          status: "",
        },
      });
    },
  });

  const handleSetReconsiderationOpt = (val: string[]) =>
    setReconsiderationOptions(val);
  const handleSetFormDocs = (val: { name: string; file: string }[]) =>
    setFormDocs(val);

  const handleNext = async () => {
    await validateForm();
    if (show === 1) {
      if (
        !_.isNull(values.arcId) &&
        !_.isEmpty(values.dateClosed) &&
        !_.isEmpty(values.dateCreated) &&
        !_.isEmpty(values.dateEnd)
      ) {
        setShow((prev) => prev + 1);
      }
    } else if (show === 2) {
      if (
        !_.isEmpty(values.description) &&
        !_.isEmpty(values.material) &&
        values.reconsiderations.length > 0
      ) {
        setShow((prev) => prev + 1);
      }
    }
  };

  const handlePrevious = () => setShow((prev) => prev - 1);

  if (saveSuccess) {
    saveReset();

    showToastSuccess({
      text: "Arc saved successfully!",
      toastId: "success",
    });
  }

  if (saveError) {
    saveReset();
    errorDisplayOrNavigate({ error: saveError, toastId: "save-error" });
  }

  return (
    <div id="content-page" className="content-inner">
      <Container>
        <Row>
          <Col sm="12">
            <Card
              className="position-relative inner-page-bg bg-primary"
              style={{ height: "150px" }}
            >
              <div className="inner-page-title">
                <h3 className="text-white">ARC Request List</h3>
                <p className="text-white">Create ARC Request</p>
              </div>
            </Card>
          </Col>
          <Col sm="12" lg="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Request Form</h4>
                </div>
              </Card.Header>
              <Card.Body className="pt-0">
                <ul id="top-tab-list" className="p-0 row list-inline">
                  <li
                    className={`${
                      show === 1 ? "active" : show > 1 ? "active done" : ""
                    } col-lg-3 col-md-6 text-start mb-2 active`}
                    id="Dates"
                  >
                    <Link to="#">
                      <i className="material-symbols-outlined">edit_calendar</i>
                      <span>Dates</span>
                    </Link>
                  </li>
                  <li
                    id="Description"
                    className={` ${
                      show === 2 ? "active" : show > 2 ? "active done" : ""
                    } col-lg-3 col-md-6 mb-2 text-start`}
                  >
                    <Link to="#">
                      <i className="material-symbols-outlined">person</i>
                      <span>Description</span>
                    </Link>
                  </li>
                  <li
                    id="payment"
                    className={` ${
                      show === 3 ? "active" : show > 3 ? "active done" : ""
                    } col-lg-3 col-md-6 mb-2 text-start`}
                  >
                    <Link to="#">
                      <i className="material-symbols-outlined">description</i>
                      <span>Documents</span>
                    </Link>
                  </li>
                  <li
                    id="confirm"
                    className={` ${
                      show === 4 ? "active" : ""
                    } col-lg-3 col-md-6 mb-2 text-start`}
                  >
                    <Link to="#">
                      <i className="material-symbols-outlined">done</i>
                      <span>Finish</span>
                    </Link>
                  </li>
                </ul>
                <Form onSubmit={handleSubmit} noValidate>
                  {show === 1 && (
                    <DatesForm
                      setFieldError={setFieldError}
                      setFieldValue={setFieldValue}
                      handleSetReconsiderationOpt={handleSetReconsiderationOpt}
                      handleSetFormDocs={handleSetFormDocs}
                      values={{
                        arcId: values.arcId ?? null,
                        dateClosed: values.dateClosed,
                        dateEnd: values.dateEnd,
                        dateStart: values.dateCreated,
                      }}
                      errorForm={{
                        arcId: errors.arcId as string,
                        dateClosed: errors.dateClosed as string,
                        dateEnd: errors.dateEnd as string,
                        dateStart: errors.dateCreated as string,
                      }}
                    />
                  )}
                  {show === 2 && (
                    <DescriptionRequest
                      setFieldError={setFieldError}
                      setFieldValue={setFieldValue}
                      values={{
                        description: values.description,
                        material: values.material,
                        reconsiderations: values.reconsiderations,
                      }}
                      errorForm={{
                        description: errors.description as string,
                        material: errors.material as string,
                        reconsiderations: errors.reconsiderations as string,
                      }}
                      reconsiderationOptions={reconsiderationOptions}
                    />
                  )}
                  {show === 3 && (
                    <DocumentsDownload
                      setFieldError={setFieldError}
                      setFieldValue={setFieldValue}
                      formDocs={formDocs}
                      errorForm={errors.forms as string}
                      values={values.forms}
                    />
                  )}
                  {show === 4 && (
                    <div className="form-card">
                      <h2 className="text-success text-center">
                        <strong>SUCCESS !</strong>
                      </h2>
                      <br />
                      <Row className="justify-content-center">
                        <div className="col-3">
                          <Image
                            src={image1}
                            className="img-fluid"
                            alt="fit-image"
                          />
                        </div>
                      </Row>
                      <br />
                      <br />
                      <Row className="justify-content-center">
                        <div className="col-7 text-center">
                          <h5 className="purple-text text-center">
                            Arc Successfully Saved
                          </h5>
                        </div>
                      </Row>
                    </div>
                  )}
                  {show === 3 && (
                    <Button
                      variant="primary"
                      className="next action-button float-end"
                      type={"submit"}
                      disabled={saveLoading}
                    >
                      {saveLoading ? "Submitting" : "Submit"}
                    </Button>
                  )}
                  {show < 3 && (
                    <Button
                      variant="primary"
                      className="next action-button float-end"
                      type={"button"}
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  )}

                  {show > 1 && show < 4 && (
                    <Button
                      variant="danger"
                      className="previous action-button-previous float-end me-3"
                      onClick={handlePrevious}
                      disabled={saveLoading}
                    >
                      Previous
                    </Button>
                  )}
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateArc;
