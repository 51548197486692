import { useState } from "react";

export interface FileViewType {
  open: boolean;
  filename?: string;
  path: string;
}

const useFileView = () => {
  const [viewDoc, setViewDoc] = useState<FileViewType>({
    open: false,
    filename: "",
    path: "",
  });

  const handleToggleViewDoc = (
    open: boolean,
    path: string,
    filename?: string
  ) => {
    console.log(path);
    const newPath = path.toLowerCase().includes("https")
      ? path
      : `${process.env.REACT_APP_MAIN_URL}${path}`;

    setViewDoc({
      open,
      path: newPath,
      filename: filename ?? "",
    });
  };

  return [viewDoc, handleToggleViewDoc] as const;
};

export default useFileView;
