import React, { useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import { toast } from "react-toastify";
import { useGetApiClient } from "../../../api/useApiClient";
// img
// import logo from "../../../assets/images/logo-full.png";
import Logo from "../../../assets/images/BE-logo.png";
import login1 from "../../../assets/images/login/1.png";
import login2 from "../../../assets/images/login/2.jpg";
import login3 from "../../../assets/images/login/3.jpg";

// Import selectors & action from setting store
import * as SettingSelector from "../../../store/setting/selectors";
// Redux Selector / Action
import { useSelector } from "react-redux";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Recoverpw = () => {
  const appName = useSelector(SettingSelector.app_name);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    console.log("submit");
    e.preventDefault();

    try {
      setLoading(true);
      const { data: forgotPassword } = await useGetApiClient.put(
        `/general/user/forgot-password`,
        {
          username: e.target.username.value,
          portal: "HOMEOWNER",
        }
      );
      if (forgotPassword?.error) throw new Error(forgotPassword?.error);
      toast.success(forgotPassword?.message);
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <section className="sign-in-page">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative">
              <div className="bg-primary w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1">
                <div className="main-circle circle-small"></div>
                <div className="main-circle circle-medium"></div>
                <div className="main-circle circle-large"></div>
                <div className="main-circle circle-xlarge"></div>
                <div className="main-circle circle-xxlarge"></div>
              </div>
              <div className="sign-in-detail container-inside-top">
                <Swiper className="list-inline m-0 p-0">
                  <ul className="swiper-wrapper list-inline m-0 p-0">
                    <SwiperSlide>
                      <img
                        src={login1}
                        className="signin-img img-fluid mb-5 rounded-3 w-75 h-75"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Welcome to Benevento East
                      </h2>
                    </SwiperSlide>
                  </ul>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <div className="sign-in-from">
                <Link
                  to={"/"}
                  className="d-inline-flex align-items-center justify-content-center gap-2"
                >
                  <img src={Logo} fluid className="w-50" alt="logo" />
                </Link>
                <p className="mt-3 font-size-16">
                  Enter your Address and we'll send you an email with
                  instructions to reset your password.
                </p>
                <Form className="mt-5" onSubmit={handleSubmit}>
                  <Form.Group className="form-group text-start mb-0">
                    <h6 className="form-label fw-bold">Address</h6>
                    <Form.Control
                      type="text"
                      className="form-control mb-0"
                      placeholder="Your Address"
                      required
                      name="username"
                    />
                  </Form.Group>
                  <button
                    type="submit"
                    className="btn btn-primary mt-4 fw-semibold text-uppercase letter-spacing-1"
                  >
                    {loading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <div
                          className="spinner-border align-items-center text-center"
                          role="status"
                          style={{
                            width: "2rem",
                            height: "2rem",
                            color: "#ffff",
                          }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      "Reset Password"
                    )}
                  </button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Recoverpw;
