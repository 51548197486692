import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Tooltip,
  Card,
  Button,
  OverlayTrigger,
  Stack,
} from "react-bootstrap";
import { saveAs } from "file-saver";

import Pagination from "../../../components/pagination";
import { useGetDocumentListQuery } from "../../../store/query/documents/documentquery";
import { errorDisplayOrNavigate } from "../../../utilities/notificationToast";

const loader = require("../../../assets//images/page-img/page-load-loader.gif");

const DocumentList = () => {
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);

  const {
    data: listData,
    isLoading: listLoading,
    error: listError,
  } = useGetDocumentListQuery(undefined, { refetchOnMountOrArgChange: true });

  const list =
    listData ??
    [
      // {
      //   id: "test",
      //   title: "Test",
      //   name: "test",
      //   type: "test",
      //   size: 123,
      //   file: "test",
      //   authorBy: "test",
      //   createdAt: "test",
      // },
    ];
  const loading = listLoading;

  if (listError) {
    errorDisplayOrNavigate({
      error: listError,
      toastId: "doc-list-error",
    });
  }

  return (
    <div className="content-inner">
      <Container>
        <Row>
          <Col sm={12}>
            <Card
              className="position-relative inner-page-bg bg-primary"
              style={{ height: "150px" }}
            >
              <div className="inner-page-title">
                <h3 className="text-white">Benevento Files and Forms</h3>
              </div>
            </Card>
          </Col>
          <Col sm={12}>
            <Card>
              <div>
                <h3 className="card-header text-center font-weight-bold text-uppercase py-4">
                  Benevento Files and Forms
                </h3>
              </div>
              <Card.Body className="pt-0">
                <Stack
                  gap={2}
                  direction="horizontal"
                  style={{ paddingBottom: 20 }}
                >
                  <div style={{ width: "50%" }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <Stack
                      gap={2}
                      direction="horizontal"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => {}}
                      >
                        Print
                      </Button>
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => {}}
                      >
                        Excel
                      </Button>
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => {}}
                      >
                        Pdf
                      </Button>
                    </Stack>
                  </div>
                </Stack>
                <div className="table-editable">
                  <table
                    className="table table-bordered table-responsive-md table-striped text-center"
                    style={{ overflowX: "auto" }}
                  >
                    <thead>
                      <tr>
                        <th>Filename</th>
                        <th>File Type</th>
                        <th>Date </th>
                        <th>Size</th>
                        <th>Author</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={6}>
                            <div className="col-sm-12 text-center">
                              <img
                                src={loader}
                                alt="loader"
                                style={{ height: "100px" }}
                              />
                            </div>
                          </td>
                        </tr>
                      ) : list.length === 0 ? (
                        <tr>
                          <td colSpan={6}>
                            <div className="col-sm-12 text-center">
                              <p>No Documents available</p>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        list.map((item, key) => (
                          <tr key={key}>
                            <td>{item.name}</td>
                            <td>{item.type}</td>
                            <td>{item.createdAt}</td>
                            <td>{item.size}</td>
                            <td>{item.authorBy}</td>
                            <td>
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Download</Tooltip>}
                              >
                                <i
                                  className="material-symbols-outlined me-1 md-18 text-primary"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => saveAs(item.file, item.name)}
                                >
                                  download
                                </i>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  page={page}
                  setPage={setPage}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  totalCount={list.length ?? 0}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DocumentList;
