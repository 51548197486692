// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#richtext-container[data-is-read=true] .ql-toolbar {
  display: none;
}

#richtext-container[data-is-read=true] .ql-disabled {
  border: 0;
}

#richtext-container[data-is-error=true] .ql-toolbar {
  border-color: #ff9b8a;
}

#richtext-container[data-is-error=true] .ql-container {
  border-color: #ff9b8a;
}

.ql-blank {
  padding-bottom: 25px;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/richtextfield.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,SAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AACA;EACE,qBAAA;AAEF;;AAAA;EACE,oBAAA;AAGF","sourcesContent":["#richtext-container[data-is-read=\"true\"] .ql-toolbar {\n  display: none;\n}\n\n#richtext-container[data-is-read=\"true\"] .ql-disabled {\n  border: 0;\n}\n\n#richtext-container[data-is-error=\"true\"] .ql-toolbar {\n  border-color: #ff9b8a;\n}\n#richtext-container[data-is-error=\"true\"] .ql-container {\n  border-color: #ff9b8a;\n}\n.ql-blank {\n  padding-bottom: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
