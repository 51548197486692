import {
  SaveViolationComment,
  SaveViolationModel,
  ViolationCommentDto,
  ViolationsListModel,
  ViolationTimelineDetailsModel,
  ViolationTimelineDto,
} from "../../../model/violations/violationsmodel";
import { apiSlice, errorHandler, ErrorResponseModel } from "..";
import { violationTags } from "../tags";

export const endpoints = {
  list: "/homeowner/violation/list",
  save: "/homeowner/violation/save",
  timeline: (id: number) => `/homeowner/violation/get-timeline/${id}`,
  "timeline-details": (id: number) => `/homeowner/violation/get/${id}`,
  "timeline-comments": (id: number, page: number, entries: number) =>
    `/homeowner/violation/comment/get/${id}?currentPage=${page}&perPage=${entries}`,
  "save-comment": "/homeowner/violation/comment/save",
  "upload-file": (id: number) => `/homeowner/violation/upload-file/${id}`,
};

export const violationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getViolationList: builder.query<ViolationsListModel, void>({
      query: () => ({
        url: endpoints.list,
        method: "GET",
      }),
      providesTags: [violationTags[0]],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    getViolationTimeline: builder.query<ViolationTimelineDto, number>({
      query: (param) => ({ url: endpoints.timeline(param), method: "GET" }),
      providesTags: (res, error, arg) => [{ type: violationTags[1], id: arg }],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    getViolationTimelineDetails: builder.query<
      ViolationTimelineDetailsModel,
      number
    >({
      query: (param) => ({
        url: endpoints["timeline-details"](param),
        method: "GET",
      }),
      providesTags: (res, error, arg) => [{ type: violationTags[2], id: arg }],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    getViolationComments: builder.query<ViolationCommentDto, QueryParamsModel>({
      query: (param) => ({
        url: endpoints["timeline-comments"](
          param.id,
          param.page,
          param.entries
        ),
        method: "GET",
      }),
      providesTags: (res, error, arg) => [
        { type: violationTags[3], id: `${arg.id}-${arg.page}-${arg.entries}` },
      ],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    saveViolation: builder.mutation<void, SaveViolationModel>({
      query: (body) => ({
        url: endpoints.save,
        method: "POST",
        body: body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    saveViolationComment: builder.mutation<void, SaveViolationComment>({
      query: (body) => ({
        url: endpoints["save-comment"],
        method: "Post",
        body,
      }),
      invalidatesTags: (res, err, args) => [violationTags[3]],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    uploadViolationFile: builder.mutation<
      void,
      {
        body: {
          file: string;
          name: string;
        };
        id: number;
      }
    >({
      query: (body) => ({
        url: endpoints["upload-file"](body.id),
        method: "Put",
        body: body.body,
      }),
      invalidatesTags: (res, error, arg) => [
        { type: violationTags[2], id: arg.id },
      ],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const {
  useGetViolationListQuery,
  useSaveViolationMutation,
  useGetViolationTimelineQuery,
  useGetViolationTimelineDetailsQuery,
  useGetViolationCommentsQuery,
  useSaveViolationCommentMutation,
  useUploadViolationFileMutation,
} = violationSlice;
