import { apiSlice, errorHandler, ErrorResponseModel } from "..";
import { blogtags } from "../tags";
import { BlogModel } from "../../../model/blog/blogmodel";

const endpoints = {
  list: "/general/blog/get",
  saveLike: "/general/blog/like/save",
  saveComment: "/general/blog/comment/save",
};

export const blogSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBlogList: builder.query<BlogModel[], void>({
      query: () => ({
        url: endpoints.list,
        method: "GET",
      }),
      providesTags: (res, err, arg) => [blogtags[0]],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    saveLike: builder.mutation<void, { blogId: number; liked: boolean }>({
      query: (body) => ({
        url: endpoints.saveLike,
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    saveComment: builder.mutation<void, { blogId: number; comment: string }>({
      query: (body) => ({
        url: endpoints.saveComment,
        method: "POST",
        body,
      }),
      //   async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      //     const patchResult = dispatch(
      //       blogSlice.util.updateQueryData("getBlogList", undefined, (draft) => {
      //         console.log(draft);
      //       })
      //     );
      //     try {
      //       await queryFulfilled;
      //     } catch {
      //       patchResult.undo();
      //     }
      //   },
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const {
  useGetBlogListQuery,
  useSaveCommentMutation,
  useSaveLikeMutation,
} = blogSlice;
