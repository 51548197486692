import { apiSlice, errorHandler, ErrorResponseModel } from "..";
import { documenttags } from "../tags";
import { DocModel } from "../../../model/documents/docModel";

const endpoints = {
  list: "/homeowner/document/list",
};

export const documentSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentList: builder.query<DocModel[], void>({
      query: () => ({
        url: endpoints.list,
        method: "GET",
      }),
      providesTags: (res, err, arg) => [documenttags[0]],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const { useGetDocumentListQuery } = documentSlice;
