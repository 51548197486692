import React from "react";
import { Modal } from "react-bootstrap";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { FileViewType } from "../hooks/useFileView";

type Props = {
  doc: FileViewType;
  handleToggleView: (open: boolean, path: string, filename?: string) => void;
};

const FileViewModal = ({ doc, handleToggleView }: Props) => {
  console.log(doc);
  return (
    <Modal
      show={doc.open}
      onHide={() => handleToggleView(false, "")}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {doc.filename}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "65vh", overflowY: "auto" }}>
        <DocViewer
          documents={[
            {
              uri: doc.path.includes("https")
                ? doc.path
                : `${process.env.REACT_APP_MAIN_URL}${doc.path}`,
            },
          ]}
          pluginRenderers={DocViewerRenderers}
        />
      </Modal.Body>
    </Modal>
  );
};

export default FileViewModal;
