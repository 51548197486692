import React, { useState } from "react";
import { Col, Form, Button, Card, Stack } from "react-bootstrap";
import { useSaveViolationCommentMutation } from "../../../store/query/violations/violationsquery";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utilities/notificationToast";
import UploadFile from "../../../components/UploadFile";
import { SaveViolationComment } from "../../../model/violations/violationsmodel";
import useFileView from "../../../hooks/useFileView";
import FileViewModal from "../../../components/FileViewModal";
import RichTextfield from "../../../components/RichTextfield";
import CameraModal from "../../../components/CameraModal";

type Props = {
  id: number;
};

const ViolationComments = ({ id }: Props) => {
  const [openCam, setOpenCam] = useState<boolean>(false);
  const [viewDoc, handleToggleDoc] = useFileView();
  const formSchema = Yup.object().shape({
    comment: Yup.string().required("Please provide a comment."),
    documents: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string(),
          file: Yup.string(),
        })
      )
      .min(1, "Please provide a document."),
  });
  const [
    saveCommentMutate,
    {
      isLoading: saveCommentLoading,
      isSuccess: saveCommentSuccess,
      reset: saveCommentReset,
      error: saveCommentError,
    },
  ] = useSaveViolationCommentMutation();

  const {
    handleSubmit,
    setFieldValue,
    setFieldError,
    values,
    errors,
    resetForm,
  } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema: formSchema,
    initialValues: {
      documents: [],
      comment: "",
    },
    onSubmit: (values) => {
      const form: SaveViolationComment = {
        comment: values.comment,
        documents: values.documents,
        violationReportId: id,
      };
      submitFormFn(form);
    },
  });

  const handleToggleCam = () => setOpenCam((prev) => !prev);

  const submitFormFn = async (form: SaveViolationComment) => {
    await saveCommentMutate(form);
    resetForm({
      values: {
        comment: "",
        documents: [],
      },
    });
  };

  const handleFormReset = () => {
    resetForm({
      values: {
        comment: "",
        documents: [],
      },
    });
    // uploadInputRef.current.value = "";
  };

  if (saveCommentError) {
    errorDisplayOrNavigate({
      error: saveCommentError,
      toastId: "save-comment",
    });

    saveCommentReset();
  }

  if (saveCommentSuccess) {
    showToastSuccess({
      text: "Comment successfully posted.",
      toastId: "success",
    });

    saveCommentReset();
  }

  return (
    <>
      {openCam && (
        <CameraModal
          open={openCam}
          handleToggle={handleToggleCam}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
          name="documents"
          docData={values.documents}
        />
      )}
      {viewDoc.open && (
        <FileViewModal doc={viewDoc} handleToggleView={handleToggleDoc} />
      )}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="form-group">
          <Form.Label>Activity & Comment</Form.Label>
          <RichTextfield
            value={values.comment}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
            errorForm={errors.comment}
          />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label>Choose File</Form.Label>
          <Stack gap={2} direction="horizontal">
            <UploadFile<{ name: string; file: string }[]>
              errorForm={errors.documents as string}
              loading={saveCommentLoading}
              setFieldError={setFieldError}
              setFieldValue={setFieldValue}
              isInvalid={!_.isEmpty(errors.documents)}
              docData={values.documents}
              name="documents"
            />
            <div
              style={{
                width: 1,
                height: 40,
                backgroundColor: "#ccc",
              }}
            />
            <Button
              type="button"
              variant="primary"
              className="d-inline-flex mb-1 me-1"
              disabled={saveCommentLoading}
              onClick={handleToggleCam}
            >
              <i className="material-symbols-outlined me-0">photo_camera</i>
            </Button>
          </Stack>
        </Form.Group>

        <Form.Group className="form-group">
          <div className="mt-3">
            {values.documents.map((doc, i) => (
              <div key={i}>
                <Card
                  onClick={() => handleToggleDoc(true, `${doc.file}`, doc.name)}
                  style={{
                    background: "#f1f1f1",
                    cursor: "pointer",
                  }}
                >
                  <Card.Body
                    style={{
                      padding: "10px 15px",
                      color: "#7e8b9a",
                    }}
                  >
                    {doc.name}
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </Form.Group>

        <Col className="mb-2">
          <Button type="submit" disabled={false}>
            Submit
          </Button>{" "}
          <Button variant="danger" onClick={handleFormReset}>
            Cancel
          </Button>
        </Col>
      </Form>
    </>
  );
};

export default ViolationComments;
