import React, { useState } from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import _ from "lodash";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  Button,
  Image,
  Modal,
  Stack,
} from "react-bootstrap";
import {
  useGetViolationListQuery,
  useSaveViolationMutation,
} from "../../../store/query/violations/violationsquery";
import { useUploadFileMutation } from "../../../store/query";
import { SaveViolationModel } from "../../../model/violations/violationsmodel";
import useFileView from "../../../hooks/useFileView";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utilities/notificationToast";
import "../../../assets/scss/fileviewer.scss";
import UploadFile from "../../../components/UploadFile";
import CameraModal from "../../../components/CameraModal";
import FileViewModal from "../../../components/FileViewModal";

const image1 = require("../../../assets/images/page-img/img-success.png");

type Props = {};

const CreateViolation = (props: Props) => {
  const [openCam, setOpenCam] = useState<boolean>(false);
  const [show, setShow] = useState("A");
  const [viewDoc, handleToggleDoc] = useFileView();
  const formValidationSchema = Yup.object().shape({
    violatorId: Yup.number().required("Please select a valid address."),
    violationId: Yup.number().required("Please select a violation type."),
    description: Yup.string().required("Please provide a description."),
    dateSeen: Yup.string().required("Please provide a valid date."),
    documents: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string(),
          file: Yup.string(),
        })
      )
      .min(1, "Please provide a document."),
  });

  const {
    data,
    isLoading: dataLoading,
    error: dataError,
  } = useGetViolationListQuery();
  const [
    saveViolation,
    {
      reset: saveReset,
      error: saveError,
      isSuccess: saveSuccess,
      isLoading: saveLoading,
    },
  ] = useSaveViolationMutation();
  const newDate = new Date().toISOString().split("T");

  const violatorsConfig = data ? data.violators : [];
  const violationsConfig = data ? data.violations : [];
  const loading = dataLoading || saveLoading;

  const submitFormFn = async (form: SaveViolationModel) => {
    await saveViolation(form);
    setShow("Information");
  };

  const {
    handleSubmit,
    setFieldValue,
    setFieldError,
    values,
    errors,
    resetForm,
    handleChange,
  } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema: formValidationSchema,
    initialValues: {
      violatorId: "",
      violationId: undefined,
      description: "",
      // dateSeen: `${newDate[0]} 00:00:00}`,
      dateSeen: new Date().toISOString().split("T")[0] + " 00:00:00",
      documents: [],
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const form: SaveViolationModel = {
        dateSeen: values.dateSeen,
        description: values.description,
        violationId: parseInt(values.violationId),
        documents: values.documents,
        violatorId: parseInt(values.violatorId),
      };

      submitFormFn(form);
      resetForm({
        values: {
          violatorId: "",
          violationId: "",
          description: "",
          // dateSeen: `${newDate[0]} 00:00:00}`,
          dateSeen: new Date().toISOString().split("T")[0] + " 00:00:00",
          documents: [],
        },
      });
      // uploadInputRef.current.value = "";
    },
  });

  const handleToggleCam = () => setOpenCam((prev) => !prev);

  if (saveSuccess) {
    saveReset();

    showToastSuccess({
      text: "Violation saved successfully!",
      toastId: "success",
    });
  }

  if (saveError) {
    saveReset();

    errorDisplayOrNavigate({ error: saveError, toastId: "save-error" });
  }

  if (dataError) {
    errorDisplayOrNavigate({ error: dataError, toastId: "data-error" });
  }

  return (
    <>
      {openCam && (
        <CameraModal<{ file: string; name: string }[]>
          open={openCam}
          handleToggle={handleToggleCam}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
          name="documents"
          docData={values.documents}
        />
      )}
      {viewDoc.open && (
        <FileViewModal doc={viewDoc} handleToggleView={handleToggleDoc} />
      )}
      <div id="content-page" className="content-inner">
        <Container>
          <Row>
            <Col sm="12">
              <Card
                className="position-relative inner-page-bg bg-primary"
                style={{ height: "150px" }}
              >
                <div className="inner-page-title">
                  <h3 className="text-white">Violations</h3>
                  <p className="text-white">Create Violations</p>
                </div>
              </Card>
            </Col>
            <Col sm={12} lg={12}>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Violations Form</h4>
                  </div>
                </Card.Header>
                <Card.Body className="pt-0">
                  <ul id="top-tab-list" className="p-0 row list-inline">
                    <li
                      className={` ${
                        show === "Description" ? "active done" : ""
                      } ${show === "Dates" ? "active done" : ""} ${
                        show === "A" ? "active " : ""
                      } col-lg-3 col-md-6 text-start mb-2 active`}
                      id="Information"
                      onClick={() => setShow("A")}
                    >
                      <Link to="#">
                        <i className="material-symbols-outlined">description</i>
                        <span>Information</span>
                      </Link>
                    </li>
                    <li
                      id="confirm"
                      className={` ${
                        show === "Information" ? "active done" : ""
                      } col-lg-3 col-md-6 mb-2 text-start`}
                    >
                      <Link to="#" style={{ cursor: "initial" }}>
                        <i className="material-symbols-outlined">done</i>
                        <span>Finish</span>
                      </Link>
                    </li>
                  </ul>
                  <div className={show === "A" ? "d-block" : "d-none"}>
                    <Form onSubmit={handleSubmit} noValidate>
                      <Row>
                        <div className="col-7">
                          <h3 className="mb-4">Request & Dates</h3>
                        </div>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Form.Group>
                            <Form.Label>Home Address *</Form.Label>
                            <Form.Select
                              name="violatorId"
                              value={values.violatorId}
                              onChange={handleChange}
                              isInvalid={!_.isEmpty(errors.violatorId)}
                              disabled={loading}
                            >
                              <option value="">Select House Address</option>
                              {violatorsConfig.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.houseName}
                                </option>
                              ))}
                            </Form.Select>
                            {errors.violatorId && (
                              <Form.Control.Feedback type="invalid">
                                {errors.violatorId as string}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group>
                            <Form.Label>Date Seen *</Form.Label>
                            <Form.Control
                              type="datetime-local"
                              name="dateSeen"
                              value={values.dateSeen}
                              onChange={handleChange}
                              isInvalid={!_.isEmpty(errors.dateSeen)}
                              disabled={loading}
                            />
                            {errors.dateSeen && (
                              <Form.Control.Feedback type="invalid">
                                {errors.dateSeen as string}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Form.Group>
                            <Form.Label>Violation Type *</Form.Label>
                            <Form.Select
                              name="violationId"
                              value={values.violationId}
                              onChange={handleChange}
                              isInvalid={!_.isEmpty(errors.violationId)}
                              disabled={loading}
                            >
                              <option value="">Select Violation Type</option>
                              {violationsConfig.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Form.Select>
                            {errors.violationId && (
                              <Form.Control.Feedback type="invalid">
                                {errors.violationId as string}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md="6">
                          <Form.Group>
                            <Form.Label>
                              Attach a File or Take a Snap *
                            </Form.Label>
                            <Stack gap={2} direction="horizontal">
                              <UploadFile<{ name: string; file: string }[]>
                                name="documents"
                                errorForm={errors.documents as string}
                                loading={loading}
                                setFieldError={setFieldError}
                                setFieldValue={setFieldValue}
                                isInvalid={!_.isEmpty(errors.documents)}
                                docData={values.documents}
                              />
                              <div
                                style={{
                                  width: 1,
                                  height: 40,
                                  backgroundColor: "#ccc",
                                }}
                              />
                              <Button
                                type="button"
                                variant="primary"
                                className="d-inline-flex mb-1 me-1"
                                disabled={loading}
                                onClick={handleToggleCam}
                              >
                                <i className="material-symbols-outlined me-0">
                                  photo_camera
                                </i>
                              </Button>
                            </Stack>
                            {/* <Form.Control
                                  ref={uploadInputRef}
                                  type="file"
                                  name="documents"
                                  isInvalid={!_.isEmpty(errors.documents)}
                                  disabled={loading}
                                  onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                  ) =>
                                    handleUpload(
                                      e.target.files[0],
                                      setFieldValue,
                                      setFieldError
                                    )
                                  }
                                />
                                {errors.documents && (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.documents as string}
                                  </Form.Control.Feedback>
                                )} */}
                            <div className="mt-3">
                              {values.documents.map((doc, i) => (
                                <div key={i}>
                                  <Card
                                    onClick={() =>
                                      handleToggleDoc(
                                        true,
                                        `${process.env.REACT_APP_MAIN_URL}${doc.file}`,
                                        doc.name
                                      )
                                    }
                                    style={{
                                      background: "#f1f1f1",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Card.Body
                                      style={{
                                        padding: "10px 15px",
                                        color: "#7e8b9a",
                                      }}
                                    >
                                      {doc.name}
                                    </Card.Body>
                                  </Card>
                                </div>
                              ))}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <Form.Label>Description *</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={values.description}
                              onChange={handleChange}
                              name="description"
                              isInvalid={!_.isEmpty(errors.description)}
                              disabled={loading}
                            />
                            {errors.description && (
                              <Form.Control.Feedback type="invalid">
                                {errors.description as string}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Button
                        variant="primary"
                        type="submit"
                        className="next action-button float-end"
                        disabled={loading}
                      >
                        Submit
                      </Button>
                    </Form>
                  </div>
                  <fieldset
                    className={`${
                      show === "Information" ? "d-block" : "d-none"
                    }`}
                  >
                    <div className="form-card">
                      <h2 className="text-success text-center">
                        <strong>SUCCESS !</strong>
                      </h2>
                      <br />
                      <Row className="justify-content-center">
                        <div className="col-3">
                          <Image
                            src={image1}
                            className="img-fluid"
                            alt="fit-image"
                          />
                        </div>
                      </Row>
                      <br />
                      <br />
                      <Row className="justify-content-center">
                        <div className="col-7 text-center">
                          <h5 className="purple-text text-center">
                            You Have Successfully Created a Violation.
                          </h5>
                        </div>
                      </Row>
                    </div>
                  </fieldset>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CreateViolation;
