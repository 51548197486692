import { apiSlice, errorHandler, ErrorResponseModel } from "..";
import { ProfileModel } from "../../../model/profile/profileModel";
import { profileTags } from "../tags";

export const endpoints = {
  details: "/homeowner/profiles/profile/get",
};

export const profileSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfileDetails: builder.query<ProfileModel, void>({
      query: () => ({
        url: endpoints.details,
        method: "GET",
      }),
      providesTags: (res, err, arg) => [profileTags[0]],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const { useGetProfileDetailsQuery } = profileSlice;
