import { apiSlice, errorHandler, ErrorResponseModel } from "..";
import { newstags } from "../tags";
import { NewsModel } from "../../../model/news/newsmodel";

const endpoints = {
  list: "/general/news/get",
};

export const newsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNewsList: builder.query<NewsModel[], void>({
      query: () => ({
        url: endpoints.list,
        method: "GET",
      }),
      providesTags: (res, err, arg) => [newstags[0]],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const { useGetNewsListQuery } = newsSlice;
