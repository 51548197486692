import React, { useRef, useEffect } from "react";
import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import "../assets/scss/richtextfield.scss";

type Props = {
  isRead?: boolean;
  value: string;
  errorForm?: string;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError?: (field: string, message: string | undefined) => void;
};

const RichTextfield = ({
  isRead = false,
  value,
  errorForm,
  setFieldError,
  setFieldValue,
}: Props) => {
  const handleCommentChange = (html: string) => {
    setFieldValue("comment", html);
    setFieldError("comment", "");
  };

  return (
    <div
      id="richtext-container"
      data-is-read={isRead}
      data-is-error={errorForm ? true : false}
    >
      <ReactQuill
        theme="snow"
        value={value}
        placeholder="Add comments here"
        onChange={isRead ? null : handleCommentChange}
        readOnly={isRead}
      />
      {errorForm && (
        <Form.Control.Feedback
          style={{ display: errorForm ? "block" : "none" }}
          type="invalid"
        >
          {errorForm as string}
        </Form.Control.Feedback>
      )}
    </div>
  );
};

export default RichTextfield;
