import { Dropdown } from "react-bootstrap";
import { DOTS, usePagination } from "../utilities/pagination";

const Pagination = ({
  page,
  setPage,
  perPage,
  setPerPage,
  totalCount,
  siblingCount = 1,
}) => {
  const dummyPerPageCount = [5, 10, 20, 50, 80, 100];

  const paginationRange = usePagination({
    currentPage: page,
    totalCount,
    siblingCount,
    pageSize: perPage,
  });

  let lastPage = paginationRange[paginationRange.length - 2];

  return (
    <div className="d-flex flex-row justify-content-between">
      <div
        className="btn-group d-flex flex-row align-items-center"
        role="group"
      >
        <span className="me-2">Show</span>
        <Dropdown>
          <Dropdown.Toggle
            as="div"
            className="p-2 rounded-3"
            style={{
              backgroundColor: "#F3F5F7",
            }}
            // as={CustomToggle}
          >
            {perPage}{" "}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {dummyPerPageCount?.map((perPageCount, idx) => (
              <Dropdown.Item
                key={idx}
                className={`${perPage === perPageCount ? "text-primary" : ""}`}
                disabled={perPage === perPageCount}
                onClick={() => {
                  setPerPage(perPageCount);
                  setPage(0);
                }}
              >
                {perPageCount}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div>
        <nav aria-label="Page navigation example">
          <ul className="pagination mb-0">
            <li
              className="page-item page-link"
              onClick={page !== 0 ? () => setPage(page - 1) : undefined}
            >
              Previous
            </li>
            {paginationRange?.map((pageNumber, idx) => {
              if (pageNumber === DOTS) {
                return (
                  <li key={idx} className={`page-item page-link`}>
                    &#8230;
                  </li>
                );
              }
              return (
                <li
                  key={idx}
                  className={`${
                    page === idx ? "active" : ""
                  } page-item page-link`}
                  onClick={page !== idx ? () => setPage(idx) : undefined}
                >
                  {pageNumber}
                </li>
              );
            })}
            <li
              className="page-item page-link"
              onClick={
                lastPage && page !== lastPage
                  ? () => setPage(page + 1)
                  : undefined
              }
            >
              Next
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Pagination;
