// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fc-button {
  text-transform: capitalize !important;
  opacity: 0.6 !important;
}

.fc-button:hover,
.fc-button-active {
  opacity: 1 !important;
}

.fc-listWeek-button {
  display: none !important;
}

.fc-dayGridDay-button {
  border-top-right-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/fullcalendarCustom.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;EACA,uBAAA;AACF;;AAEA;;EAEE,qBAAA;AACF;;AAEA;EACE,wBAAA;AACF;;AAEA;EACE,0CAAA;EACA,6CAAA;EACA,oCAAA;EACA,uCAAA;AACF","sourcesContent":[".fc-button {\n  text-transform: capitalize !important;\n  opacity: 0.6 !important;\n}\n\n.fc-button:hover,\n.fc-button-active {\n  opacity: 1 !important;\n}\n\n.fc-listWeek-button {\n  display: none !important;\n}\n\n.fc-dayGridDay-button {\n  border-top-right-radius: 0.25em !important;\n  border-bottom-right-radius: 0.25em !important;\n  border-top-left-radius: 0 !important;\n  border-bottom-left-radius: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
