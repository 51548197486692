import React, { Fragment } from "react";
import { Dropdown, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// images

import user1 from "../assets/images/page-img/19.jpg";
import user2 from "../assets/images/page-img/18.jpg";
import user3 from "../assets/images/page-img/20.jpg";
import user4 from "../assets/images/user/06.jpg";
import user5 from "../assets/images/user/08.jpg";
import user6 from "../assets/images/user/15.jpg";
import user7 from "../assets/images/user/13.jpg";
import { Link } from "react-router-dom";

const type = ["VIOLATION", "ARC", "EVENT"];

const SearchModal = ({ data }) => {
  const navigate = useNavigate();

  const navigateToHomeOwner = (id, type) => {
    if (type === "ARC") {
      localStorage.setItem("selectedArcRequestId", id);
      navigate("/dashboard/app/arc-requests/timeline/details");
    } else if (type === "VIOLATION") {
      navigate(`/dashboard/app/violation-requests/timeline/details/${id}`);
    }
  };

  return (
    <Fragment>
      <Dropdown.Menu bsPrefix=" " className={`search-modal-custom`}>
        <div className="search-modal-content">
          <Modal.Body className=" p-0">
            <div className="item-header-scroll">
              <div className="search-modal-body">
                {data.length > 0 ? (
                  data.map((item, key) => (
                    <div
                      className="d-flex align-items-center search-hover py-2 px-3"
                      key={key}
                    >
                      {/* <div className="flex-shrink-0">
                                        <img
                                            src={user1}
                                            className="align-self-center img-fluid avatar-50 rounded-pill"
                                            alt="#"
                                        />
                                    </div> */}
                      <div className="d-flex ms-3 w-100 justify-content-between">
                        <div className="d-flex flex-column">
                          <div>
                            <p
                              className="h6"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigateToHomeOwner(item.id, item.type)
                              }
                            >
                              {item.name}
                            </p>
                            <span className="profile-status-online"></span>
                          </div>
                          <span className="mb-0">{item.type}</span>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p style={{ paddingLeft: 20 }}>No Search Result</p>
                )}
              </div>
            </div>
          </Modal.Body>
        </div>
      </Dropdown.Menu>
    </Fragment>
  );
};

export default SearchModal;
