import React, { ChangeEvent } from "react";
import { Row, Form, Col } from "react-bootstrap";
import _ from "lodash";
import { useGetArcListQuery } from "../../../store/query/arc/arcQuery";
import { errorDisplayOrNavigate } from "../../../utilities/notificationToast";

type Props = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string | undefined) => void;
  values: {
    arcId: number | null;
    dateStart: string;
    dateClosed: string;
    dateEnd: string;
  };
  errorForm: {
    arcId: string;
    dateStart: string;
    dateClosed: string;
    dateEnd: string;
  };
  handleSetReconsiderationOpt: (val: string[]) => void;
  handleSetFormDocs: (val: { name: string; file: string }[]) => void;
};

const DatesForm = ({
  setFieldError,
  setFieldValue,
  errorForm,
  values,
  handleSetReconsiderationOpt,
  handleSetFormDocs,
}: Props) => {
  const {
    data: dataList,
    isLoading: dataIsLoading,
    error: dataError,
  } = useGetArcListQuery();

  const arcTypes = dataList?.arcTypes ?? [];

  const handleFieldChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    const arcType = arcTypes.find((item) => item.id === value);
    handleSetReconsiderationOpt(arcType.reconsiderations);
    handleSetFormDocs(arcType.forms);
    setFieldValue("arcId", value, false);
    setFieldError("arcId", "");
    setFieldValue("reconsiderations", []);
  };

  const handleDateChange = (name: string, e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFieldValue(name, value, false);
    setFieldError(name, "");
  };

  if (dataError) {
    errorDisplayOrNavigate({
      error: dataError,
      toastId: "data-error",
    });
  }

  return (
    <div className="form-card text-start">
      <Row>
        <div className="col-7">
          <h3 className="mb-4">Request & Dates</h3>
        </div>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group controlId="arcType">
            <Form.Label>ARC Type: *</Form.Label>
            <Form.Select
              required
              name="arcId"
              disabled={dataIsLoading}
              value={values.arcId ?? ""}
              isInvalid={!_.isEmpty(errorForm.arcId)}
              onChange={handleFieldChange}
            >
              <option value="">Select Arc Type</option>
              {arcTypes.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
            {errorForm.arcId && (
              <Form.Control.Feedback type="invalid">
                {errorForm.arcId}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-group">
            <Form.Label>Start Date: *</Form.Label>
            <Form.Control
              type="date"
              name="dateCreated"
              value={values.dateStart.toString()}
              isInvalid={!_.isEmpty(errorForm.dateStart)}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleDateChange("dateCreated", e)
              }
            />
            {errorForm.dateStart && (
              <Form.Control.Feedback type="invalid">
                {errorForm.dateStart}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-group">
            <Form.Label>End Date: *</Form.Label>
            <Form.Control
              type="date"
              name="dateEnd"
              value={values.dateEnd.toString()}
              isInvalid={!_.isEmpty(errorForm.dateEnd)}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleDateChange("dateEnd", e)
              }
            />
            {errorForm.dateEnd && (
              <Form.Control.Feedback type="invalid">
                {errorForm.dateEnd}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="form-group">
            <Form.Label>Closing Date: *</Form.Label>
            <Form.Control
              type="date"
              name="dateClosed"
              value={values.dateClosed.toString()}
              isInvalid={!_.isEmpty(errorForm.dateClosed)}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleDateChange("dateClosed", e)
              }
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default DatesForm;
