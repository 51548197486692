import { useState } from "react";
import { useLocation } from "react-router-dom";

const useTableParameters = (fromUrl: boolean) => {
  const location = useLocation();
  const [page, setPage] = useState<number>(1);
  const [entries, setEntries] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  if (fromUrl) {
    const pageParam = Number(new URLSearchParams(location.search).get("page"));
    const entriesParam = Number(
      new URLSearchParams(location.search).get("entries")
    );
    const searchParam = new URLSearchParams(location.search).get("search");

    return { pageParam, searchParam, entriesParam };
  } else {
    const handleChangePage = (isNext: boolean, pageValue?: number) => {
      if (isNext) {
        setPage(pageValue ? pageValue : (prev) => prev + 1);
      } else {
        setPage(pageValue ? pageValue : (prev) => prev - 1);
      }
    };

    const handleEntriesChange = (entry: number) => setEntries(entry);

    const handleSearchChange = (search: string) => setSearch(search);

    return {
      page,
      handleChangePage,
      entries,
      handleEntriesChange,
      search,
      handleSearchChange,
    };
  }
};

export default useTableParameters;
